import React, { useState } from "react";
import { useSubmissionAnswersQuery } from "../graphql/generated/Form.generated";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import ImageFullDisplay from "./ImageFullDisplay";
import Spinner from "./Spinner";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
  submissionId: string;
  submittedTime: string;
  submittedBy: string;
  open: boolean;
  onClose: () => void;
}

export default function SubmissionDetail({
  submissionId,
  submittedTime,
  submittedBy,
  open,
  onClose,
}: Props) {
  const [openImage, setOpenImage] = useState("");

  const { data, loading } = useSubmissionAnswersQuery({
    variables: {
      submissionId,
    },
    fetchPolicy: "network-only",
  });
  const answers = data?.submissionAnswers;

  if (loading) return <Spinner />;
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <Box textAlign={"center"} sx={{ marginTop: "2%" }}>
          <Typography>{submittedBy}</Typography>
          <Typography>
            {submittedTime
              ? moment(submittedTime).format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </Typography>
        </Box>
      </DialogTitle>
      <Box>
        {answers
          ?.slice(0, answers.length)
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((a) => {
            return (
              <Card
                sx={{
                  border: 0.5,
                  ":hover": {
                    boxShadow: 20,
                  },
                  margin: "5%",
                  borderRadius: "20px",
                }}
                key={a.id}
              >
                <CardContent>
                  <Box textAlign="center">
                    <Typography
                      sx={{ typography: { xs: "h5", sm: "h4`", md: "h4" } }}
                    >
                      {a.description}
                    </Typography>
                    {a.answerType === "text" && (
                      <TextField
                        multiline
                        rows={5}
                        fullWidth
                        disabled={true}
                        label={""}
                        value={a.answer}
                      />
                    )}
                    {a.answerType === "singleChoice" && (
                      <RadioGroup
                        row
                        sx={{
                          display: "flow",
                        }}
                        value={a.answer}
                      >
                        {a.answerChoices.map((i) => {
                          return (
                            <FormControlLabel
                              key={`${a.id}-${i}`}
                              value={i}
                              control={<Radio />}
                              label={i}
                              disabled={true}
                            />
                          );
                        })}
                      </RadioGroup>
                    )}

                    {a.answerType === "multipleChoices" && (
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormGroup>
                          <Stack
                            justifyContent={"center"}
                            direction="row"
                            spacing={2}
                            sx={{ paddingTop: "5%" }}
                          >
                            {a.answerChoices.map((t) => {
                              return (
                                <FormControlLabel
                                  disabled={true}
                                  control={
                                    <Checkbox
                                      checked={a.answer!.split("|").includes(t)}
                                    />
                                  }
                                  label={t}
                                  key={`${a.id}-${t}`}
                                />
                              );
                            })}
                          </Stack>
                        </FormGroup>
                      </FormControl>
                    )}

                    {a.answerType === "yesno" && (
                      <RadioGroup
                        value={a.answer}
                        row
                        sx={{
                          display: "flow",
                        }}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={<Radio />}
                          label={"Yes"}
                          disabled={true}
                        />
                        <FormControlLabel
                          value={"No"}
                          control={<Radio />}
                          label={"No"}
                          disabled={true}
                        />
                      </RadioGroup>
                    )}
                    {a.answerType === "checkbox" && (
                      <Checkbox
                        disabled={true}
                        checked={a.answer === "checked"}
                      />
                    )}
                    {a.answerType === "n/a" && <div></div>}
                  </Box>
                </CardContent>
                <Stack
                  justifyContent={"center"}
                  direction="row"
                  sx={{
                    paddingTop: "5%",
                    paddingBottom: "2%",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  {a.imageUrls &&
                    a.imageUrls.map((i) => {
                      return (
                        <div style={{ paddingRight: "2%" }}>
                          <CardMedia
                            sx={{
                              padding: "1em 1em 0 1em",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            component="img"
                            alt="Uploaded Image"
                            height="80"
                            image={i}
                            key={i}
                            title="Uploaded Image"
                            onClick={() => setOpenImage(i)}
                          />
                          {openImage && openImage === i && (
                            <ImageFullDisplay
                              imageUrl={i}
                              open={!!openImage}
                              onClose={() => setOpenImage("")}
                            />
                          )}
                        </div>
                      );
                    })}
                </Stack>
              </Card>
            );
          })}
      </Box>
    </Dialog>
  );
}
