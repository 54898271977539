import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Spinner from "./Spinner";
import { useCreateSupportCaseMutation } from "../graphql/generated/SupportCase.generated";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import palette from "../theme/palette";

interface SupportCaseForm {
  subject: string;
  message: string;
}

export default function SupportCase() {
  const [caseSubmitted, setCaseSubmitted] = useState(false);

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const defaultValues = {
    subject: "",
    message: "",
  };

  const [formId] = useState(
    "create-support-case" + Math.random().toString(36).slice(2),
  );

  const [createCase] = useCreateSupportCaseMutation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    // getValues,
  } = useForm<SupportCaseForm>({
    defaultValues,
  });

  const onSubmit = useCallback(
    async (values: SupportCaseForm) => {
      try {
        await createCase({
          variables: {
            subject: values.subject,
            message: values.message,
          },
        });
        setCaseSubmitted(true);
        //toast.info("Your message is successfully submitted, we will contact you soon")
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
      }
    },
    [createCase, setCaseSubmitted],
  );

  if (isSubmitting) return <Spinner />;

  return (
    <div
      style={{
        paddingLeft: isDesktop ? "20%" : "5%",
        paddingRight: isDesktop ? "20%" : "5%",
      }}
    >
      {!caseSubmitted ? (
        <Box textAlign={"center"}>
          <Typography variant={"h3"}>Leave us a message</Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
            noValidate
            style={{ marginTop: "2%" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name={"subject"}
                  rules={{
                    required: "The name field is required",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={"Subject"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name={"message"}
                  rules={{
                    required: "The name field is required",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      multiline
                      rows={10}
                      style={{ marginTop: "2%", marginBottom: "2%" }}
                      label={"Message"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: palette.primary.main,
                  color: "white",
                },
              }}
              disabled={isSubmitting}
              type={"submit"}
              form={formId}
            >
              {isSubmitting ? "Sending" : "Send"}
            </Button>
            <ToastContainer />
          </form>
        </Box>
      ) : (
        <Typography variant={"h3"}>
          Thank you for contacting us and your message is submitted
          successfully, we will contact you soon
        </Typography>
      )}
    </div>
  );
}
