import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Spinner from "./Spinner";
import {
  useDeleteQuestionMutation,
  useQuestionsQuery,
} from "../graphql/generated/Question.generated";
import AddQuestion from "./AddQuestion";
import ConfirmationDialog, { MessageType } from "./ConfirmationDialog";
import palette from "../theme/palette";

function convertAnswerType(answerType: string) {
  if (answerType.toUpperCase() === "SINGLECHOICE") return "Single Choice";
  else if (answerType.toUpperCase() === "TEXT") return "Text Input";
  else if (answerType.toUpperCase() === "MULTIPLECHOICES")
    return "Multiple Choices";
  else if (answerType.toUpperCase() === "YESNO") return "Yes/No";
  else if (answerType.toUpperCase() === "CHECKBOX") return "Checkbox";
  else if (answerType.toUpperCase() === "N/A") return "N/A";
  return null;
}

export default function FormQuestions({ formId }: { formId: string }) {
  const [addQuestion, setAddQuestion] = useState("");
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [mutateDeleteQuestion] = useDeleteQuestionMutation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { data, loading, refetch } = useQuestionsQuery({
    variables: {
      formId: formId,
    },
    fetchPolicy: "network-only",
  });

  const questionRows = (data?.questions || [])
    .map((q) => {
      return {
        id: q.id,
        description: q.description,
        displayOrder: q.displayOrder,
        answerType: convertAnswerType(q.answerType),
        answerChoices: q.answerChoices,
      };
    })
    .sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });

  if (loading) return <Spinner />;

  return (
    <div>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
        }}
      >
        <Typography variant={"h4"}>Questions</Typography>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: palette.primary.main,
              color: "white",
            },
          }}
          onClick={() => {
            setAddQuestion(formId);
          }}
        >
          Create
        </Button>
      </Stack>
      <Grid
        container
        spacing={5}
        style={{
          paddingTop: "5%",
          paddingLeft: isDesktop ? "10%" : "5%",
          paddingRight: isDesktop ? "10%" : "5%",
          paddingBottom: "20%",
        }}
      >
        {questionRows.map((q) => {
          return (
            <Grid item xs={12} md={4} lg={4} key={q.id}>
              <Card
                sx={{
                  border: 1,
                  height: "100%",
                  borderColor: "rgb(212,213,213)",
                  // ":hover": {
                  //   borderColor: "#635DFF",
                  //   ":hover": {
                  //     boxShadow: 15,
                  //   },
                  // },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography variant={"h5"}>
                    <span style={{ fontWeight: 1000 }}>Order:</span>{" "}
                    {q.displayOrder}
                  </Typography>
                  <Typography variant={"h5"}>
                    <span style={{ fontWeight: 1000 }}>Type:</span>{" "}
                    {q.answerType}
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                    variant={"h5"}
                  >
                    <span style={{ fontWeight: 1000 }}>Description:</span>{" "}
                    {q.description}
                  </Typography>

                  {q.answerType!.indexOf("Choice") > 0 && (
                    <Typography variant={"h5"}>
                      <span style={{ fontWeight: 1000 }}>Choices:</span>
                      {q.answerChoices.map((c) => {
                        return <Typography>{c}</Typography>;
                      })}
                    </Typography>
                  )}
                </CardContent>
                <CardActions
                  style={{
                    marginTop: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: palette.primary.main,
                        color: "white",
                      },
                    }}
                    onClick={() => setDeleteQuestionId(q.id)}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {addQuestion && (
        <div key={formId}>
          <AddQuestion
            formId={formId}
            refetch={refetch}
            open={!!addQuestion}
            onClose={() => {
              setAddQuestion("");
            }}
          />
        </div>
      )}
      {deleteQuestionId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deleteQuestionId}
          onClose={() => setDeleteQuestionId("")}
          onConfirm={async () => {
            await mutateDeleteQuestion({
              variables: {
                id: deleteQuestionId,
              },
            });
            setDeleteQuestionId("");
            await refetch();
          }}
          text="Are you sure to delete this question?"
        />
      )}
    </div>
  );
}
