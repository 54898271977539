import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GridViewIcon from "@mui/icons-material/GridView";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useMediaQuery, useTheme } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
const drawerWidth = "10%";
const useStyles = makeStyles({
  root: {
    position: "fixed", // Change from "display: fixed" to "position: fixed"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    paddingTop: "5%",
  },
  content: {
    flexGrow: 1,
    padding: "20px",
  },
});

const activeColor = "#221af6";
const fontColor = "#635DFF";
const iconSize = 40;
export default function Sidebar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  return isDesktop ? (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
          <ListItem
            component={Link}
            to="/"
            sx={{ flexDirection: "column", paddingTop: "20%" }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <GridViewIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{
                alignItems: "center",
                color: path === "/dashboard" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/projects"
            sx={{ flexDirection: "column", paddingTop: "20%" }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <WorkIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/projects" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Projects"
              sx={{
                alignItems: "center",
                color: path === "/projects" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/forms"
            sx={{ flexDirection: "column", paddingTop: "20%" }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <AssignmentIcon
                style={{
                  fontSize: iconSize,
                  color:
                    path === "/forms" || path.startsWith("/form")
                      ? activeColor
                      : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Forms"
              sx={{
                alignItems: "center",
                color:
                  path === "/forms" || path.startsWith("/form")
                    ? activeColor
                    : fontColor,
              }}
            />
          </ListItem>
          {/*<ListItem*/}
          {/*  component={Link}*/}
          {/*  to="/recipients"*/}
          {/*  sx={{ flexDirection: "column", paddingTop: "20%" }}*/}
          {/*>*/}
          {/*  <ListItemIcon sx={{ justifyContent: "center" }}>*/}
          {/*    <PeopleIcon*/}
          {/*      style={{*/}
          {/*        fontSize: iconSize,*/}
          {/*        color: path === "/recipients" ? activeColor : "grey",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText*/}
          {/*    primary="Recipients"*/}
          {/*    sx={{*/}
          {/*      alignItems: "center",*/}
          {/*      color: "#1C77C3",*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</ListItem>*/}
          <ListItem
            component={Link}
            to="/submissions"
            sx={{ flexDirection: "column", paddingTop: "20%" }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <FactCheckIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/submissions" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Submissions"
              sx={{
                alignItems: "center",
                color: path === "/submissions" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/support"
            sx={{ flexDirection: "column", paddingTop: "20%" }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <MessageIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/support" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Support"
              sx={{
                alignItems: "center",
                color: path === "/support" ? activeColor : fontColor,
              }}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  ) : (
    <div></div>
  );
}
