import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import AddProject from "./AddProject";
import {
  useDeleteProjectMutation,
  useProjectCardsQuery,
} from "../graphql/generated/Project.generated";
import Spinner from "./Spinner";
import moment from "moment-timezone";
import EditProject from "./EditProject";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConfirmationDialog, { MessageType } from "./ConfirmationDialog";
import palette from "../theme/palette";

export const TableHeadColour = "#1976d2";

export default function ProjectList() {
  const [addProject, setAddProject] = useState(false);
  const [deleteProjectMutation] = useDeleteProjectMutation();
  const [deleteProjectId, setDeleteProjectId] = useState("");

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { data, loading, refetch } = useProjectCardsQuery({
    fetchPolicy: "network-only",
  });
  const [editProjectId, setEditProjectId] = useState("");

  if (loading) return <Spinner />;

  return (
    <div style={{ paddingLeft: isDesktop ? "10%" : "0%" }}>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAddProject(true);
          }}
        >
          Add Project
        </Button>

        {data?.projectCards.length === 0 && (
          <Typography sx={{ marginTop: "2%" }}>
            No projects available, please create your first project.
          </Typography>
        )}
      </Box>

      {addProject && (
        <AddProject
          refetch={refetch}
          open={addProject}
          onClose={() => {
            setAddProject(false);
          }}
        />
      )}
      <Grid
        container
        spacing={5}
        sx={{
          paddingTop: "5%",
          paddingLeft: isDesktop ? "10%" : "5%",
          paddingRight: isDesktop ? "10%" : "5%", // paddingBottom: "20%",
          // border: "1px solid red"
        }}
      >
        {data?.projectCards
          .slice(0, data?.projectCards.length)
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            return 0;
          })
          .map((p) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={p.id}>
                <Card
                  sx={{
                    border: 1,
                    borderColor: "rgb(212,213,213)",
                    // ":hover": {
                    //   borderColor: "#635DFF",
                    //   ":hover": {
                    //     boxShadow: 15,
                    //   },
                    // },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Box textAlign={"center"}>
                      <Typography variant={"h2"}>{p.name} </Typography>
                    </Box>
                    <Grid sx={{ paddingTop: "20%", paddingLeft: "5%" }}>
                      <Typography>Timezone: {p.timezone}</Typography>
                    </Grid>

                    <Grid sx={{ paddingTop: "2%", paddingLeft: "5%" }}>
                      <Typography>
                        Forms: {p.numberOfActiveForms}{" "}
                        <span style={{ color: "green", fontWeight: 1000 }}>
                          active
                        </span>
                        , {p.numberOfDraftForms}{" "}
                        <span style={{ color: "orange", fontWeight: 1000 }}>
                          draft
                        </span>
                        , {p.numberOfInactiveForms}{" "}
                        <span style={{ color: "grey", fontWeight: 1000 }}>
                          inactive
                        </span>
                      </Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: "2%", paddingLeft: "5%" }}>
                      <Typography>
                        Submissions: {p.numberOfSubmissions}
                      </Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: "2%", paddingLeft: "5%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "2%",
                          flexWrap: "wrap",
                        }}
                      >
                        <AccessTimeIcon />{" "}
                        <Typography fontSize={"12px"}>
                          Created {moment().diff(moment(p.createdAt), "d")}{" "}
                          day(s) ago
                        </Typography>
                      </div>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2} // Adjust the value as needed for spacing
                      style={{
                        marginLeft: isDesktop ? "5%" : "5%",
                        marginRight: isDesktop ? "5%" : "5%",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                          },
                        }}
                        onClick={() => setDeleteProjectId(p.id)}
                      >
                        Delete
                      </Button>

                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                          },
                        }}
                        onClick={() => setEditProjectId(p.id)}
                      >
                        Edit
                      </Button>

                      <Link to={`/forms?projectId=${p.id}`}>
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: palette.primary.main,
                              color: "white",
                            },
                          }}
                        >
                          Forms
                        </Button>
                      </Link>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {editProjectId && (
        <EditProject
          id={editProjectId}
          open={!!editProjectId}
          onClose={() => setEditProjectId("")}
          refetch={refetch}
        />
      )}

      {deleteProjectId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deleteProjectId}
          onClose={() => setDeleteProjectId("")}
          onConfirm={async () => {
            await deleteProjectMutation({
              variables: {
                id: deleteProjectId,
              },
            });
            setDeleteProjectId("");
            await refetch();
          }}
          text="Are you sure to delete this project?"
        />
      )}
    </div>
  );
}
