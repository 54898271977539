import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useRecipientAuthCodeMutation,
  useRecipientAuthCodeVerifyMutation,
} from "../graphql/generated/Recipient.generated";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export enum ContactType {
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
}

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  recipientId: string;
  contact: string;
  type: ContactType;
}

export default function ContactVerify({
  open,
  onClose,
  recipientId,
  contact,
  type,
}: ConfirmationDialogProps) {
  const [submitting, setSubmitting] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [codeSending, setCodeSending] = useState(false);

  const [authCode, setAuthCode] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [recipientAuthCode] = useRecipientAuthCodeMutation();
  const [recipientAuthCodeVerify] = useRecipientAuthCodeVerifyMutation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ fontSize: isDesktop ? 40 : 25 }} />
        </IconButton>
        <Box textAlign={"center"}>
          <Typography variant={"h3"}>{contact}</Typography>
        </Box>
      </DialogTitle>
      {codeSent && (
        <DialogContent>
          <Typography style={{ marginBottom: "2%" }}>
            Please input the 6-digits verification code
          </Typography>
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Required"
            placeholder="Verification code"
            onChange={(e) => setAuthCode(e.target.value)}
          />
        </DialogContent>
      )}

      {verifyStatus.toUpperCase() === "FAILED" && (
        <Box textAlign={"center"}>
          <Typography color={"red"}>Invalid auth code</Typography>
        </Box>
      )}

      <DialogActions
        style={{ justifyContent: "center", gap: "30px" }}
        sx={{ paddingBottom: "8%" }}
      >
        {!codeSent && (
          <Button
            onClick={async () => {
              setCodeSending(true);
              await recipientAuthCode({
                variables: {
                  id: recipientId,
                  type: type,
                  contact: contact,
                },
              });
              setCodeSending(false);
              setCodeSent(true);
            }}
            disabled={codeSending}
            variant={"contained"}
          >
            {codeSending ? "Sending" : "Send code"}
          </Button>
        )}
        <Button
          sx={{
            borderRadius: "3",
            // opacity: "0.8",
            // color: "white",
            // "&:hover": {
            //   opacity: "1.0",
            // },
          }}
          variant={"contained"}
          onClick={async () => {
            setSubmitting(true);
            const res = await recipientAuthCodeVerify({
              variables: {
                id: recipientId,
                type: type,
                contact: contact,
                authCode,
              },
            });
            const status = res.data?.recipientAuthCodeVerify ?? "";
            setVerifyStatus(status);
            if (status?.toUpperCase() === "SUCCESS") {
              onClose();
            }
            setSubmitting(false);
          }}
          disabled={submitting}
        >
          {submitting ? "Verifying" : "Verify"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
