import { Controller, useForm } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import { useUpsertQuestionMutation } from "../graphql/generated/Question.generated";

interface FormValues {
  description: string;
  answerRequired: boolean;
  imageRequired: boolean;
  answerType: string;
  questionOrder: number;
}

interface AddQuestionProps {
  formId: string;
  refetch: () => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "10%",
  },
  divider: {
    color: palette.text.prompt,
  },
}));

export default function AddQuestion({
  formId,
  open,
  onClose,
  refetch,
}: AddQuestionProps) {
  const defaultValues = {
    description: "",
    answerRequired: true,
    imageRequired: false,
    answerType: "",
    questionOrder: 0,
  };
  const [questionFormId] = useState(
    "create-question" + Math.random().toString(36).slice(2),
  );

  const [mutateUpsertQuestion, { error }] = useUpsertQuestionMutation();

  const [isAnswerChoices, setIsAnswerChoices] = useState(false);
  const [choiceList, setChoiceList] = useState<
    Array<{
      order: number;
      choice: string;
    }>
  >([]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    // getValues,
  } = useForm<FormValues>({
    defaultValues,
  });
  const classes = useStyles();

  useEffect(() => {
    if (error?.graphQLErrors?.[0]?.message) {
      toast.error(error?.graphQLErrors[0].message);
    }
  }, [error]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await mutateUpsertQuestion({
          variables: {
            formId: formId,
            input: {
              description: values.description,
              displayOrder: values.questionOrder.toString(),
              answerRequired: false,
              answerType: values.answerType,
              answerChoices: choiceList.map((t) => t.choice),
              imageRequired: false,
            },
          },
        });
        onClose();
        refetch();
      } catch (e) {
        console.log("Error happens.");
      } finally {
      }
    },
    [formId, mutateUpsertQuestion, onClose, refetch, choiceList],
  );

  return (
    <Dialog open={open} scroll={"paper"} fullWidth={true}>
      <DialogContent dividers>
        <Typography
          sx={{
            typography: { xs: "h5r", sm: "h5r", md: "h5r" },
            paddingBottom: "2%",
          }}
        >
          All fields listed with * are required
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={questionFormId}
          noValidate
          className={classes.formStyle}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name={"description"}
                rules={{
                  required: "The name field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    multiline
                    rows={5}
                    label={"Please enter the question description"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>
                Please enter the question order(questions will be displayed by
                question order)
              </Typography>
              <Controller
                control={control}
                name={"questionOrder"}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    type={"number"}
                    {...field}
                    fullWidth={false}
                    required
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <Typography>Does this question require an answer?</Typography>*/}
            {/*  <Controller*/}
            {/*    control={control}*/}
            {/*    name={"answerRequired"}*/}
            {/*    render={({ field, fieldState }) => {*/}
            {/*      const fieldId = "method";*/}
            {/*      return (*/}
            {/*        <>*/}
            {/*          <FormControl*/}
            {/*            component="fieldset"*/}
            {/*            error={Boolean(fieldState.error)}*/}
            {/*            id={fieldId}*/}
            {/*            required={true}*/}
            {/*          >*/}
            {/*            <RadioGroup*/}
            {/*              {...field}*/}
            {/*              value={field.value}*/}
            {/*              onChange={(e) => {*/}
            {/*                console.log(e.target.value);*/}
            {/*                field.onChange(e.target.value === "true");*/}
            {/*                setAnswerRequired(e.target.value === "true");*/}
            {/*              }}*/}
            {/*              row*/}
            {/*            >*/}
            {/*              <FormControlLabel*/}
            {/*                value={true}*/}
            {/*                control={<Radio />}*/}
            {/*                label="Yes"*/}
            {/*              />*/}
            {/*              <FormControlLabel*/}
            {/*                value={false}*/}
            {/*                control={<Radio />}*/}
            {/*                label="No"*/}
            {/*              />*/}
            {/*            </RadioGroup>*/}
            {/*            <FormHelperText>*/}
            {/*              {fieldState.error?.message || " "}*/}
            {/*            </FormHelperText>*/}
            {/*          </FormControl>*/}
            {/*        </>*/}
            {/*      );*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <Typography>
                Please specify the answer type for this question
              </Typography>
              <Controller
                control={control}
                name={"answerType"}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => {
                  const fieldId = "method";
                  return (
                    <>
                      <FormControl
                        component="fieldset"
                        error={Boolean(fieldState.error)}
                        id={fieldId}
                        required
                      >
                        <RadioGroup
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setIsAnswerChoices(
                              e.target.value === "singleChoice" ||
                                e.target.value === "multipleChoices",
                            );
                          }}
                          row
                        >
                          <FormControlLabel
                            value={"yesno"}
                            control={<Radio />}
                            label="Yes/No"
                          />
                          <FormControlLabel
                            value={"text"}
                            control={<Radio />}
                            label="Text Input"
                          />
                          <FormControlLabel
                            value={"singleChoice"}
                            control={<Radio />}
                            label="Single Choice"
                          />
                          <FormControlLabel
                            value={"multipleChoices"}
                            control={<Radio />}
                            label="Multiple Choices"
                          />
                          <FormControlLabel
                            value={"checkbox"}
                            control={<Radio />}
                            label="Checkbox"
                          />
                          <FormControlLabel
                            value={"n/a"}
                            control={<Radio />}
                            label="N/A"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {fieldState.error?.message || " "}
                        </FormHelperText>
                      </FormControl>
                    </>
                  );
                }}
              />
            </Grid>

            {isAnswerChoices && (
              <Grid item xs={12} md={12}>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    let copy = cloneDeep(choiceList);
                    copy.push({
                      order:
                        copy.length === 0 ? 0 : copy[copy.length - 1].order + 1,
                      choice: "",
                    });
                    setChoiceList(copy);
                  }}
                >
                  Add Choice
                </Button>
                <Grid container rowGap={2} sx={{ paddingTop: "2%" }}>
                  {choiceList.map((c) => {
                    return (
                      <Grid
                        container
                        key={c.order}
                        style={{
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={9} md={9} lg={9}>
                          <TextField
                            name="choice"
                            required
                            fullWidth={true}
                            id={c.order.toString()}
                            autoFocus
                            value={c.choice}
                            onChange={(e) => {
                              let copy = cloneDeep(choiceList);
                              const editIndex = copy.findIndex(
                                (t) => t.order === c.order,
                              );
                              copy[editIndex].choice = e.target.value;
                              setChoiceList(copy);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                          <Button
                            onClick={() => {
                              let copy = cloneDeep(choiceList);
                              const removeIndex = copy.findIndex(
                                (t) => t.order === c.order,
                              );
                              copy.splice(removeIndex, 1);
                              setChoiceList(copy);
                            }}
                          >
                            Remove
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}

            {/*<Grid item xs={12}>*/}
            {/*  <Typography>*/}
            {/*    Does this question require user to upload images?*/}
            {/*  </Typography>*/}
            {/*  <Controller*/}
            {/*    control={control}*/}
            {/*    name={"imageRequired"}*/}
            {/*    render={({ field, fieldState }) => {*/}
            {/*      const fieldId = "method";*/}
            {/*      return (*/}
            {/*        <>*/}
            {/*          <FormControl*/}
            {/*            component="fieldset"*/}
            {/*            error={Boolean(fieldState.error)}*/}
            {/*            id={fieldId}*/}
            {/*            required={true}*/}
            {/*          >*/}
            {/*            <RadioGroup*/}
            {/*              {...field}*/}
            {/*              value={field.value}*/}
            {/*              onChange={(e) => {*/}
            {/*                field.onChange(e.target.value === "true");*/}
            {/*              }}*/}
            {/*              row*/}
            {/*            >*/}
            {/*              <FormControlLabel*/}
            {/*                value={true}*/}
            {/*                control={<Radio />}*/}
            {/*                label="Yes"*/}
            {/*              />*/}
            {/*              <FormControlLabel*/}
            {/*                value={false}*/}
            {/*                control={<Radio />}*/}
            {/*                label="No"*/}
            {/*              />*/}
            {/*            </RadioGroup>*/}
            {/*          </FormControl>*/}
            {/*        </>*/}
            {/*      );*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type={"submit"}
          form={questionFormId}
        >
          {isSubmitting ? "Saving" : "Create"}
        </Button>
        <ToastContainer />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
