import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDashboardQuery } from "../graphql/generated/Project.generated";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SubmissionDetail from "./SubmissionDetail";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Spinner from "./Spinner";
import palette from "../theme/palette";

const iconSize = 30;
export default function Dashboard() {
  const [openRow, setOpenRow] = useState<any>();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { data, loading } = useDashboardQuery({
    fetchPolicy: "network-only",
  });
  const stat = data?.dashboard;
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "formName",
      headerName: "Form name",
      headerClassName: "super-app-theme--header",
      width: 300,
      sortable: false,
    },
    {
      field: "projectName",
      headerName: "Project",
      headerClassName: "super-app-theme--header",
      width: 300,
      sortable: false,
    },
    {
      field: "submittedAt",
      headerName: "Submitted Time",
      headerClassName: "super-app-theme--header",
      width: 300,
      sortable: false,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
    },
    {
      field: "action",
      headerName: "",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 150,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: palette.primary.main,
                color: "white",
              },
            }}
            onClick={() => setOpenRow(params.row)}
          >
            View
          </Button>
        );
      },
    },
  ];

  const rows =
    stat?.recentSubmissions.map((t) => {
      return {
        id: t.id,
        formName: t.form.name,
        projectName: t.form.project.name,
        submittedAt: moment(t.submittedAt).format("YYYY-MM-DD HH:mm:ss"),
        submittedBy: t.submittedBy,
      };
    }) || [];
  if (loading) return <Spinner />;

  return (
    <div style={{ paddingLeft: isDesktop ? "10%" : "5%" }}>
      <Typography
        sx={{
          paddingLeft: isDesktop ? "10%" : "0%",
          paddingBottom: "2%",
          typography: { xs: "h4", sm: "h3", md: "h3" },
        }}
        variant={"h2"}
      >
        Overview
      </Typography>
      <Grid
        container
        item
        spacing={5}
        sx={{
          paddingLeft: isDesktop ? "10%" : "0%",
          paddingRight: isDesktop ? "10%" : "5%",
        }}
      >
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              border: 1,
              height: "100%",
              borderColor: "rgb(212,213,213)",
              // ":hover": {
              //   borderColor: "#635DFF",
              //   ":hover": {
              //     boxShadow: 15,
              //   },
              // },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <WorkIcon style={{ fontSize: iconSize }} />
                  <Typography
                    variant="h3"
                    paddingLeft="2%"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {stat?.numberOfProjects} project(s)
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
            <CardActions
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to={"/projects"}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: palette.primary.main,
                      color: "white",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              border: 1,
              height: "100%",
              borderColor: "rgb(212,213,213)",
              // ":hover": {
              //   borderColor: "#635DFF",
              //   ":hover": {
              //     boxShadow: 15,
              //   },
              // },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <AssignmentIcon style={{ fontSize: iconSize }} />
                  <Typography
                    variant="h3"
                    paddingLeft="2%"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {stat?.numberOfForms} form(s)
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
            <CardActions
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to={"/forms"}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: palette.primary.main,
                      color: "white",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              border: 1,
              height: "100%",
              borderColor: "rgb(212,213,213)",
              // ":hover": {
              //   borderColor: "#635DFF",
              //   ":hover": {
              //     boxShadow: 15,
              //   },
              // },
            }}
          >
            <CardContent>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <FactCheckIcon style={{ fontSize: iconSize }} />
                  <Typography
                    variant="h3"
                    paddingLeft="2%"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {stat?.numberOfSubmissions} submission(s)
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
            <CardActions
              style={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to={"/submissions"}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: palette.primary.main,
                      color: "white",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingLeft: isDesktop ? "10%" : "0%",
          marginTop: "8%",
        }}
      >
        <Typography
          sx={{
            paddingBottom: "1%",
            typography: { xs: "h4", sm: "h3", md: "h3" },
          }}
        >
          Latest 10 submissions
        </Typography>
        {isDesktop ? (
          <Box
            sx={{
              height: rows.length * 100 + 200,
              "& .super-app-theme--header": {
                backgroundColor: "#fafafa",
                color: "black",
              },
              marginBottom: "5%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              disableRowSelectionOnClick
              onRowClick={(r) => {
                setOpenRow(r.row);
              }}
              sx={{
                boxShadow: 2,
                minWidth: "700px",
                "& .MuiDataGrid-row": {
                  borderTop: "2px solid lightgray",
                },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                  overflow: "visible !important",
                },
                // "& .MuiDataGrid-sortIcon": {
                //   color: "white",
                // },
                // "& .MuiDataGrid-menuIconButton": {
                //   color: "white",
                // },
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
                marginRight: "10%",
              }}
            />{" "}
          </Box>
        ) : rows.length === 0 ? (
          <Typography>No submissions</Typography>
        ) : (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            style={{
              paddingTop: "5%",
              paddingRight: isDesktop ? "10%" : "5%",
              marginBottom: "20%",
            }}
          >
            {rows.map((r) => {
              return (
                <Grid item xs={12} key={r.id}>
                  <Card
                    sx={{
                      border: 1,
                      borderColor: "rgb(212,213,213)",
                      // ":hover": {
                      //   borderColor: "#635DFF",
                      //   ":hover": {
                      //     boxShadow: 15,
                      //   },
                      // },
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent>
                      <Typography>{r.formName}</Typography>
                      <Typography>{r.projectName}</Typography>
                      <Typography variant={"h6"}>
                        Submitted by: {r.submittedBy}
                      </Typography>
                      <Typography variant={"h6"}>
                        Submitted at: {r.submittedAt}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        marginTop: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                          },
                        }}
                        onClick={() => setOpenRow(r)}
                      >
                        View
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        {openRow && (
          <SubmissionDetail
            submissionId={openRow.id}
            open={!!openRow.id}
            onClose={() => setOpenRow(null)}
            submittedTime={openRow.submittedAt}
            submittedBy={openRow.submittedBy ?? ""}
          />
        )}
      </Box>
    </div>
  );
}
