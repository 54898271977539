import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSubmissionsQuery } from "../graphql/generated/Form.generated";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SubmissionDetail from "./SubmissionDetail";
import moment from "moment-timezone";
import Spinner from "./Spinner";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import palette from "../theme/palette";

export default function FormSubmissions() {
  const [searchParams] = useSearchParams();
  const formId = searchParams.get("formId");
  const [openRow, setOpenRow] = useState<any>();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { data, loading } = useSubmissionsQuery({
    variables: {
      formId: formId ?? "",
    },
    fetchPolicy: "network-only",
  });

  const rows =
    data?.submissions?.map((t) => {
      return {
        id: t.id,
        formName: t.form.name,
        projectName: t.form.project.name,
        status: t.status,
        createdAt: moment(t.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        submittedAt: t.submittedAt
          ? moment(t.submittedAt).format("YYYY-MM-DD HH:mm:ss")
          : "",
        submittedBy: t.submittedBy,
      };
    }) || [];

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "formName",
      headerName: "Form Name",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "projectName",
      headerName: "Project",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created Time",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "submittedAt",
      headerName: "Submitted Time",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   headerClassName: "super-app-theme--header",
    //   width: 100,
    // },
    {
      field: "action",
      headerName: "",
      sortable: false,
      headerClassName: "super-app-theme--header",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: palette.primary.main,
                color: "white",
              },
            }}
            onClick={() => setOpenRow(params.row)}
          >
            View
          </Button>
        );
      },
    },
  ];
  if (loading) return <Spinner />;

  return (
    <div style={{ paddingLeft: isDesktop ? "10%" : "0%" }}>
      <Box
        sx={{
          height: rows.length * 100 + 200,
          "& .super-app-theme--header": {
            backgroundColor: "#fafafa",
            color: "black",
          },
          marginBottom: "5%",
          marginLeft: "5%",
        }}
      >
        {isDesktop ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            onRowClick={(r) => {
              setOpenRow(r.row);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            sx={{
              boxShadow: 2,
              minWidth: "700px",
              "& .MuiDataGrid-row": {
                borderTop: "2px solid lightgray",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
                overflow: "visible !important",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
              "& .MuiDataGrid-menuIconButton": {
                color: "white",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
              marginRight: "10%",
            }}
            disableRowSelectionOnClick
          />
        ) : rows.length === 0 ? (
          <Typography>No submissions</Typography>
        ) : (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            style={{
              paddingTop: "5%",
              paddingRight: "5%",
            }}
          >
            {rows.map((r) => {
              return (
                <Grid item xs={12} key={r.id}>
                  <Card
                    sx={{
                      border: 1,
                      borderColor: "rgb(212,213,213)",
                      // ":hover": {
                      //   borderColor: "#635DFF",
                      //   ":hover": {
                      //     boxShadow: 15,
                      //   },
                      // },
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent>
                      <Typography>{r.formName}</Typography>
                      <Typography>{r.projectName}</Typography>
                      <Typography variant={"h6"}>
                        Submitted by: {r.submittedBy}
                      </Typography>
                      <Typography variant={"h6"}>
                        Submitted at: {r.submittedAt}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        marginTop: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                          },
                        }}
                        onClick={() => setOpenRow(r)}
                      >
                        View
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        {openRow && (
          <SubmissionDetail
            submissionId={openRow.id}
            open={!!openRow.id}
            onClose={() => setOpenRow(null)}
            submittedTime={openRow.submittedAt}
            submittedBy={openRow.submittedBy ?? ""}
          />
        )}
      </Box>
    </div>
  );
}
