import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{
  userExtRef: Types.Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, emailVerified: boolean, mobileNumber?: string | null, mobileNumberVerified: boolean, latestSubscription?: { __typename?: 'UserSubscription', startDate: any, endDate: any } | null } };

export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, mobileNumber?: string | null } };

export type UserMobileAuthMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  mobileNumber: Types.Scalars['String']['input'];
}>;


export type UserMobileAuthMutation = { __typename?: 'Mutation', userMobileAuthCode: string };


export const UserDocument = gql`
    query User($userExtRef: String!) {
  user(userExtRef: $userExtRef) {
    id
    firstName
    lastName
    email
    emailVerified
    mobileNumber
    mobileNumberVerified
    latestSubscription {
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userExtRef: // value for 'userExtRef'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UserInput!) {
  updateUser(input: $input) {
    id
    firstName
    lastName
    mobileNumber
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserMobileAuthDocument = gql`
    mutation UserMobileAuth($id: String!, $mobileNumber: String!) {
  userMobileAuthCode(id: $id, mobileNumber: $mobileNumber)
}
    `;
export type UserMobileAuthMutationFn = Apollo.MutationFunction<UserMobileAuthMutation, UserMobileAuthMutationVariables>;

/**
 * __useUserMobileAuthMutation__
 *
 * To run a mutation, you first call `useUserMobileAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMobileAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMobileAuthMutation, { data, loading, error }] = useUserMobileAuthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useUserMobileAuthMutation(baseOptions?: Apollo.MutationHookOptions<UserMobileAuthMutation, UserMobileAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserMobileAuthMutation, UserMobileAuthMutationVariables>(UserMobileAuthDocument, options);
      }
export type UserMobileAuthMutationHookResult = ReturnType<typeof useUserMobileAuthMutation>;
export type UserMobileAuthMutationResult = Apollo.MutationResult<UserMobileAuthMutation>;
export type UserMobileAuthMutationOptions = Apollo.BaseMutationOptions<UserMobileAuthMutation, UserMobileAuthMutationVariables>;