import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FormsQuery = { __typename?: 'Query', forms?: Array<{ __typename?: 'Form', id: string, name: string, createdAt: any, status: string, qrS3SignedUrl: string, deletedAt?: any | null, numberOfSubmissions: number, numberOfQuestions: number, numberOfRecipients: number, project: { __typename?: 'Project', name: string } }> | null };

export type FormQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type FormQuery = { __typename?: 'Query', form: { __typename?: 'Form', id: string, name: string, sendDays?: string | null, sendTime?: string | null, reminderSendDays?: string | null, reminderSendTime?: string | null, enableQrScanPasscode: boolean, qrScanPasscode: string, qrS3SignedUrl: string, startDate?: string | null, endDate?: string | null, comment?: string | null, userPlan: string, status: string, project: { __typename?: 'Project', id: string, name: string } } };

export type UpsertFormMutationVariables = Types.Exact<{
  input: Types.FormInput;
}>;


export type UpsertFormMutation = { __typename?: 'Mutation', upsertForm: { __typename?: 'Form', name: string, enableQrScanPasscode: boolean, qrScanPasscode: string, sendDays?: string | null, sendTime?: string | null, reminderSendDays?: string | null, reminderSendTime?: string | null, startDate?: string | null, endDate?: string | null, comment?: string | null } };

export type DeleteFormMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteFormMutation = { __typename?: 'Mutation', deleteForm: string };

export type ActivateFormMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ActivateFormMutation = { __typename?: 'Mutation', activateForm: { __typename?: 'Form', id: string, status: string } };

export type DeactivateFormMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeactivateFormMutation = { __typename?: 'Mutation', deactivateForm: { __typename?: 'Form', id: string, status: string } };

export type SubmissionsQueryVariables = Types.Exact<{
  formId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SubmissionsQuery = { __typename?: 'Query', submissions?: Array<{ __typename?: 'FormSubmission', id: string, createdAt: any, submittedBy?: string | null, submittedAt?: any | null, status?: string | null, form: { __typename?: 'Form', name: string, project: { __typename?: 'Project', name: string } } }> | null };

export type SubmissionAnswersQueryVariables = Types.Exact<{
  submissionId: Types.Scalars['String']['input'];
}>;


export type SubmissionAnswersQuery = { __typename?: 'Query', submissionAnswers?: Array<{ __typename?: 'SubmissionAnswer', id: string, description: string, answerType: string, answerChoices: Array<string>, displayOrder: number, answer?: string | null, imageUrls?: Array<string> | null }> | null };


export const FormsDocument = gql`
    query Forms($projectId: String) {
  forms(projectId: $projectId) {
    id
    name
    createdAt
    status
    qrS3SignedUrl
    deletedAt
    project {
      name
    }
    numberOfSubmissions
    numberOfQuestions
    numberOfRecipients
  }
}
    `;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFormsQuery(baseOptions?: Apollo.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export function useFormsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsSuspenseQueryHookResult = ReturnType<typeof useFormsSuspenseQuery>;
export type FormsQueryResult = Apollo.QueryResult<FormsQuery, FormsQueryVariables>;
export const FormDocument = gql`
    query Form($id: String!) {
  form(id: $id) {
    id
    name
    sendDays
    sendTime
    reminderSendDays
    reminderSendTime
    enableQrScanPasscode
    qrScanPasscode
    qrS3SignedUrl
    startDate
    endDate
    comment
    userPlan
    project {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables> & ({ variables: FormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export function useFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormSuspenseQueryHookResult = ReturnType<typeof useFormSuspenseQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const UpsertFormDocument = gql`
    mutation UpsertForm($input: FormInput!) {
  upsertForm(input: $input) {
    name
    enableQrScanPasscode
    qrScanPasscode
    sendDays
    sendTime
    reminderSendDays
    reminderSendTime
    startDate
    endDate
    comment
  }
}
    `;
export type UpsertFormMutationFn = Apollo.MutationFunction<UpsertFormMutation, UpsertFormMutationVariables>;

/**
 * __useUpsertFormMutation__
 *
 * To run a mutation, you first call `useUpsertFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFormMutation, { data, loading, error }] = useUpsertFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertFormMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFormMutation, UpsertFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFormMutation, UpsertFormMutationVariables>(UpsertFormDocument, options);
      }
export type UpsertFormMutationHookResult = ReturnType<typeof useUpsertFormMutation>;
export type UpsertFormMutationResult = Apollo.MutationResult<UpsertFormMutation>;
export type UpsertFormMutationOptions = Apollo.BaseMutationOptions<UpsertFormMutation, UpsertFormMutationVariables>;
export const DeleteFormDocument = gql`
    mutation DeleteForm($id: String!) {
  deleteForm(id: $id)
}
    `;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;
export const ActivateFormDocument = gql`
    mutation ActivateForm($id: String!) {
  activateForm(id: $id) {
    id
    status
  }
}
    `;
export type ActivateFormMutationFn = Apollo.MutationFunction<ActivateFormMutation, ActivateFormMutationVariables>;

/**
 * __useActivateFormMutation__
 *
 * To run a mutation, you first call `useActivateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateFormMutation, { data, loading, error }] = useActivateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateFormMutation(baseOptions?: Apollo.MutationHookOptions<ActivateFormMutation, ActivateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateFormMutation, ActivateFormMutationVariables>(ActivateFormDocument, options);
      }
export type ActivateFormMutationHookResult = ReturnType<typeof useActivateFormMutation>;
export type ActivateFormMutationResult = Apollo.MutationResult<ActivateFormMutation>;
export type ActivateFormMutationOptions = Apollo.BaseMutationOptions<ActivateFormMutation, ActivateFormMutationVariables>;
export const DeactivateFormDocument = gql`
    mutation DeactivateForm($id: String!) {
  deactivateForm(id: $id) {
    id
    status
  }
}
    `;
export type DeactivateFormMutationFn = Apollo.MutationFunction<DeactivateFormMutation, DeactivateFormMutationVariables>;

/**
 * __useDeactivateFormMutation__
 *
 * To run a mutation, you first call `useDeactivateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateFormMutation, { data, loading, error }] = useDeactivateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateFormMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateFormMutation, DeactivateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateFormMutation, DeactivateFormMutationVariables>(DeactivateFormDocument, options);
      }
export type DeactivateFormMutationHookResult = ReturnType<typeof useDeactivateFormMutation>;
export type DeactivateFormMutationResult = Apollo.MutationResult<DeactivateFormMutation>;
export type DeactivateFormMutationOptions = Apollo.BaseMutationOptions<DeactivateFormMutation, DeactivateFormMutationVariables>;
export const SubmissionsDocument = gql`
    query Submissions($formId: String) {
  submissions(formId: $formId) {
    id
    createdAt
    submittedBy
    submittedAt
    status
    form {
      name
      project {
        name
      }
    }
  }
}
    `;

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
      }
export function useSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
        }
export function useSubmissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
        }
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsSuspenseQueryHookResult = ReturnType<typeof useSubmissionsSuspenseQuery>;
export type SubmissionsQueryResult = Apollo.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const SubmissionAnswersDocument = gql`
    query SubmissionAnswers($submissionId: String!) {
  submissionAnswers(submissionId: $submissionId) {
    id
    description
    answerType
    answerChoices
    displayOrder
    answer
    imageUrls
  }
}
    `;

/**
 * __useSubmissionAnswersQuery__
 *
 * To run a query within a React component, call `useSubmissionAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionAnswersQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useSubmissionAnswersQuery(baseOptions: Apollo.QueryHookOptions<SubmissionAnswersQuery, SubmissionAnswersQueryVariables> & ({ variables: SubmissionAnswersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>(SubmissionAnswersDocument, options);
      }
export function useSubmissionAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>(SubmissionAnswersDocument, options);
        }
export function useSubmissionAnswersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>(SubmissionAnswersDocument, options);
        }
export type SubmissionAnswersQueryHookResult = ReturnType<typeof useSubmissionAnswersQuery>;
export type SubmissionAnswersLazyQueryHookResult = ReturnType<typeof useSubmissionAnswersLazyQuery>;
export type SubmissionAnswersSuspenseQueryHookResult = ReturnType<typeof useSubmissionAnswersSuspenseQuery>;
export type SubmissionAnswersQueryResult = Apollo.QueryResult<SubmissionAnswersQuery, SubmissionAnswersQueryVariables>;