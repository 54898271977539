import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import { useUpsertRecipientMutation } from "../graphql/generated/Recipient.generated";

interface FormValues {
  firstName: string;
  lastName: string;
  sendMethod: string;
  email: string;
  mobileNumber: string;
}

interface AddRecipientProps {
  formId: string;
  refetch: () => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "2%",
  },
  divider: {
    color: palette.text.prompt,
  },
}));

export default function AddRecipient({
  formId,
  open,
  onClose,
  refetch,
}: AddRecipientProps) {
  const [useEmail, setUseEmail] = useState<boolean | null>(null);
  const [mobile, setMobile] = useState("");

  const [upsertRecipient] = useUpsertRecipientMutation();
  const defaultValues = {
    firstName: "",
    lastName: "",
    sendMethod: "",
    email: "",
    mobileNumber: "",
  };
  const [recipientFormId] = useState(
    "create-recipient" + Math.random().toString(36).slice(2),
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    // getValues,
  } = useForm<FormValues>({
    defaultValues,
  });
  const classes = useStyles();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await upsertRecipient({
          variables: {
            formId: formId,
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              sendMethod: values.sendMethod,
              email: values.email,
              mobileNumber: mobile ? "+" + mobile : "",
            },
          },
        });
        onClose();
        refetch();
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
      }
    },
    [formId, onClose, refetch, upsertRecipient, mobile],
  );

  return (
    <Dialog open={open} scroll={"paper"} fullWidth={true}>
      <DialogContent dividers sx={{ paddingBottom: "20%" }}>
        <Typography
          sx={{
            typography: { xs: "h5r", sm: "h5r", md: "h5r" },
            paddingBottom: "2%",
          }}
        >
          All fields listed with * are required
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={recipientFormId}
          noValidate
          className={classes.formStyle}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"firstName"}
                rules={{
                  required: "The name field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={"First Name"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"lastName"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Last Name (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ paddingTop: "5%" }}>
                Which way do you like to send the form to recipients?
              </Typography>
              <Controller
                control={control}
                name={"sendMethod"}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormControl
                        component="fieldset"
                        error={Boolean(fieldState.error)}
                        id={"sendMethod"}
                        required
                      >
                        <RadioGroup
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            if (e.target.value === "Email") setUseEmail(true);
                            else setUseEmail(false);
                          }}
                          row
                        >
                          <FormControlLabel
                            value={"Email"}
                            control={<Radio />}
                            label="Email"
                          />
                          <FormControlLabel
                            value={"SMS"}
                            control={<Radio />}
                            label="SMS"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {fieldState.error?.message || " "}
                        </FormHelperText>
                      </FormControl>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"email"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required={!!useEmail}
                    label={"Email"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"mobileNumber"}
                render={({ field: { onChange, onBlur } }) => (
                  <PhoneInput
                    placeholder="Enter phone number"
                    onChange={(value) => {
                      onChange(value);
                      setMobile(value);
                    }}
                    onBlur={onBlur}
                    enableSearch={true}
                    country={"au"}
                    inputStyle={{ width: "100%" }} // Adjust input width
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
        {/*<Typography*/}
        {/*  sx={{*/}
        {/*    typography: { xs: "h5r", sm: "h5r", md: "h5r" },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Authentication code will only be sent right after creation to valid email and/or mobile number*/}
        {/*</Typography>*/}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type={"submit"}
          form={recipientFormId}
        >
          {isSubmitting ? "Saving" : "Create"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
