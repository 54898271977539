import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import dayjs from "dayjs";
import { useUpsertFormMutation } from "../graphql/generated/Form.generated";
import { useProjectsQuery } from "../graphql/generated/Project.generated";
import Spinner from "./Spinner";

interface FormValues {
  projectId: string;
  name: string;
  startDate?: string;
  endDate?: string;
  enableQRScanProtection: string;
  qrScanPasscode: string;
  // sendDays: Array<string>;
  // sendTime: Array<string>;
  // reminderSendDays: Array<string>;
  // reminderSendTime: Array<string>;
  comment?: string;
}

interface AddFormProps {
  refetch: () => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "10%",
  },
  divider: {
    color: palette.text.prompt,
  },
}));

export const daysOption = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const timeOption = [
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
];

export default function AddForm({ open, onClose, refetch }: AddFormProps) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const defaultValues = {
    projectId: projectId ? projectId : "",
    name: "",
    startDate: "",
    endDate: "",
    comment: "",
    enableQRScanProtection: "no",
    qrScanPasscode: "",
    // sendDays: [],
    // sendTime: [],
    // reminderSendDays: [],
    // reminderSendTime: [],
  };
  const [formId] = useState(
    "create-form" + Math.random().toString(36).slice(2),
  );

  const { data, loading } = useProjectsQuery({
    fetchPolicy: "network-only",
  });

  const projects =
    data?.projects
      .filter((t) => !t.deletedAt)
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
        };
      }) || [];
  const [mutateUpsertForm] = useUpsertFormMutation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues,
  });
  const classes = useStyles();
  const [usePasscode, setUsePasscode] = useState(false);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await mutateUpsertForm({
          variables: {
            input: {
              projectId: values.projectId,
              name: values.name,
              comment: values.comment,
              startDate: values.startDate
                ? dayjs(values.startDate).format("YYYY-MM-DD")
                : "",
              endDate: values.endDate
                ? dayjs(values.endDate).format("YYYY-MM-DD")
                : "",
              enableQrScanPasscode:
                values.enableQRScanProtection.toUpperCase() === "YES",
              qrScanPasscode: values.qrScanPasscode,
              // sendTime: values.sendTime.join("|"),
              // sendDays: values.sendDays.join("|"),
              // reminderSendTime: values.reminderSendTime.join("|"),
              // reminderSendDays: values.reminderSendDays.join("|"),
            },
          },
        });
        onClose();
        refetch();
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
      }
    },
    [mutateUpsertForm, onClose, refetch],
  );

  if (loading) return <Spinner />;

  if (data?.projects.length === 0)
    return (
      <Box textAlign={"center"}>
        <Typography color={"red"}>Please create a project first</Typography>
      </Box>
    );

  return (
    <Dialog open={open} scroll={"paper"} fullWidth={true}>
      <DialogContent dividers>
        <Typography
          sx={{
            typography: { xs: "h5r", sm: "h5r", md: "h5r" },
            paddingBottom: "2%",
          }}
        >
          All fields listed with * are required
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formId}
          noValidate
          className={classes.formStyle}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"name"}
                rules={{
                  required: "Form name field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={"Form name"}
                    error={Boolean(fieldState.error)}
                    // helperText={
                    //   fieldState.error?.message || (
                    //     <span style={{ color: "red" }}>
                    //       The name field can't be changed after creation
                    //     </span>
                    //   )
                    // }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"projectId"}
                rules={{
                  required: "Project needs to be selected",
                }}
                render={({ field, fieldState }) => {
                  return (
                    <div>
                      {projectId ? (
                        <TextField
                          fullWidth
                          required
                          label={"Project"}
                          {...field}
                          disabled
                          select
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          value={projectId ?? ""}
                        >
                          {projects.map((p) => {
                            return (
                              <MenuItem key={p.id} value={p.id}>
                                {p.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          select
                          label={"Project"}
                          {...field}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                        >
                          {projects.map((p) => {
                            return (
                              <MenuItem key={p.id} value={p.id}>
                                {p.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    </div>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: "5%" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography>Enable QR code scan protection?</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={"enableQRScanProtection"}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <FormControl
                            component="fieldset"
                            error={Boolean(fieldState.error)}
                            id={"enableProtection"}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <RadioGroup
                              {...field}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setUsePasscode(
                                  e.target.value.toUpperCase() === "YES",
                                );
                              }}
                              row
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {fieldState.error?.message || " "}
                            </FormHelperText>
                          </FormControl>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {usePasscode && (
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name={"qrScanPasscode"}
                  rules={{
                    required:
                      "Passcode is required if QR scan protection is selected",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required={usePasscode}
                      label={"QR scan passcode (case sensitive)"}
                      placeholder={
                        "User must input correct passcode to view the questions after scanning"
                      }
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      // helperText={
                      //   fieldState.error?.message || (
                      //     <span style={{ color: "red" }}>
                      //       The name field can't be changed after creation
                      //     </span>
                      //   )
                      // }
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"startDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="Start Date (optional)"
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"endDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="End Date (optional)"
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  );
                }}
              />
            </Grid>
            {/*<Grid item xs={12} md={12}>*/}
            {/*  <Typography sx={{ paddingTop: "2%" }}>*/}
            {/*    Select what days and time you'd like to send the survey to*/}
            {/*    recipients (optional)*/}
            {/*  </Typography>*/}
            {/*  <Stack justifyContent={"center"} direction="row" spacing={2}>*/}
            {/*    <Grid item xs={6} md={6}>*/}
            {/*      <Controller*/}
            {/*        control={control}*/}
            {/*        name={"sendDays"}*/}
            {/*        render={({ field: { value, onBlur, ...otherOptions } }) => {*/}
            {/*          return (*/}
            {/*            <Select*/}
            {/*              multiple*/}
            {/*              fullWidth*/}
            {/*              required={false}*/}
            {/*              value={value}*/}
            {/*              displayEmpty={true}*/}
            {/*              renderValue={(selected) => {*/}
            {/*                return selected*/}
            {/*                  ?.map((t) => t.substring(0, 3))*/}
            {/*                  .join(", ");*/}
            {/*              }}*/}
            {/*              MenuProps={MenuProps}*/}
            {/*              {...otherOptions}*/}
            {/*            >*/}
            {/*              {daysOption.map((option) => {*/}
            {/*                return (*/}
            {/*                  <MenuItem key={option} value={option}>*/}
            {/*                    <Checkbox checked={value.includes(option)} />*/}
            {/*                    <ListItemText primary={option} />*/}
            {/*                  </MenuItem>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </Select>*/}
            {/*          );*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={6} md={6}>*/}
            {/*      <Controller*/}
            {/*        control={control}*/}
            {/*        name={"sendTime"}*/}
            {/*        render={({ field: { value, onBlur, ...otherOptions } }) => {*/}
            {/*          return (*/}
            {/*            <Select*/}
            {/*              multiple*/}
            {/*              value={value}*/}
            {/*              fullWidth*/}
            {/*              required={false}*/}
            {/*              displayEmpty={true}*/}
            {/*              renderValue={(selected) => {*/}
            {/*                return selected?.join(", ");*/}
            {/*              }}*/}
            {/*              MenuProps={MenuProps}*/}
            {/*              {...otherOptions}*/}
            {/*            >*/}
            {/*              {timeOption.map((option) => {*/}
            {/*                return (*/}
            {/*                  <MenuItem key={option} value={option}>*/}
            {/*                    <Checkbox checked={value.includes(option)} />*/}
            {/*                    <ListItemText primary={option} />*/}
            {/*                  </MenuItem>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </Select>*/}
            {/*          );*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*  </Stack>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={12}>*/}
            {/*  <Typography sx={{ paddingTop: "2%" }}>*/}
            {/*    Select what days and time you'd like to send the reminder*/}
            {/*    notifications to users (optional)*/}
            {/*  </Typography>*/}
            {/*  <Stack justifyContent={"center"} direction="row" spacing={2}>*/}
            {/*    <Grid item xs={6} md={6}>*/}
            {/*      <Controller*/}
            {/*        control={control}*/}
            {/*        name={"reminderSendDays"}*/}
            {/*        render={({ field: { value, onBlur, ...otherOptions } }) => {*/}
            {/*          return (*/}
            {/*            <Select*/}
            {/*              multiple*/}
            {/*              fullWidth*/}
            {/*              required={false}*/}
            {/*              value={value}*/}
            {/*              displayEmpty={true}*/}
            {/*              renderValue={(selected) => {*/}
            {/*                return selected*/}
            {/*                  ?.map((t) => t.substring(0, 3))*/}
            {/*                  .join(", ");*/}
            {/*              }}*/}
            {/*              MenuProps={MenuProps}*/}
            {/*              {...otherOptions}*/}
            {/*            >*/}
            {/*              {daysOption.map((option) => {*/}
            {/*                return (*/}
            {/*                  <MenuItem key={option} value={option}>*/}
            {/*                    <Checkbox checked={value.includes(option)} />*/}
            {/*                    <ListItemText primary={option} />*/}
            {/*                  </MenuItem>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </Select>*/}
            {/*          );*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={6} md={6}>*/}
            {/*      <Controller*/}
            {/*        control={control}*/}
            {/*        name={"reminderSendTime"}*/}
            {/*        render={({ field: { value, onBlur, ...otherOptions } }) => {*/}
            {/*          return (*/}
            {/*            <Select*/}
            {/*              multiple*/}
            {/*              value={value}*/}
            {/*              fullWidth*/}
            {/*              required={false}*/}
            {/*              displayEmpty={true}*/}
            {/*              renderValue={(selected) => {*/}
            {/*                return selected?.join(", ");*/}
            {/*              }}*/}
            {/*              MenuProps={MenuProps}*/}
            {/*              {...otherOptions}*/}
            {/*            >*/}
            {/*              {timeOption.map((option) => {*/}
            {/*                return (*/}
            {/*                  <MenuItem key={option} value={option}>*/}
            {/*                    <Checkbox checked={value.includes(option)} />*/}
            {/*                    <ListItemText primary={option} />*/}
            {/*                  </MenuItem>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </Select>*/}
            {/*          );*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*  </Stack>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      typography: { xs: "h5r", sm: "h5r", md: "h5r" },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    If reminder send time is before survey send time on the same*/}
            {/*    day, the reminder will be sent anyway*/}
            {/*  </Typography>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      typography: { xs: "h5r", sm: "h5r", md: "h5r" },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    If reminder send time is after survey send time on the same day,*/}
            {/*    the reminder will only be sent if recipients haven't submitted*/}
            {/*    the survey*/}
            {/*  </Typography>*/}
            {/*  <Typography*/}
            {/*    sx={{*/}
            {/*      typography: { xs: "h5r", sm: "h5r", md: "h5r" },*/}
            {/*      paddingBottom: "2%",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    If survey send time is not configured, the reminder will be sent*/}
            {/*    anyway*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name={"comment"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    sx={{ paddingTop: "2%" }}
                    multiline
                    rows={10}
                    fullWidth
                    required={false}
                    label={"Comment (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type={"submit"}
          form={formId}
        >
          {isSubmitting ? "Saving" : "Create"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
