import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosContext } from "../lib/axios";

export default function PasswordResetSubmit() {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    try {
      axiosContext()
        .post(`/password-reset-request-token-verify`, {
          token: token,
        })
        .then((response) => {
          setMessage(response.data?.message);
        });
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const handlePasswordReset = async () => {
    setLoading(true);
    const res = await axiosContext().post("/password-reset", {
      token,
      password,
    });

    if (res.data?.message !== "SUCCESS") {
      setMessage(res.data?.message);
    } else {
      navigate("/login");
    }
    setLoading(false);
  };

  return (
    <>
      <Container>
        <CssBaseline />
        <IconButton style={{ marginTop: "1%" }}>
          <img
            height={"50px"}
            src={
              "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
            }
            alt="Logo"
            className="logo"
          />
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, paddingLeft: "2%" }}
            style={{
              fontSize: "18px",
              fontWeight: 1000,
            }}
          >
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {message === "INVALID_TOKEN" && (
            <Typography>
              Invalid link address, if this was you, please return to the login
              page and select "Forgot Your Password" to send a new email.{" "}
            </Typography>
          )}
          {message === "TOKEN_EXPIRED" && (
            <Typography>
              Link expired, if this was you, please return to the login page and
              select "Forgot Your Password" to send a new email.{" "}
            </Typography>
          )}
          {message === "SUCCESS" && (
            <Box sx={{ marginTop: "10%" }}>
              <TextField
                required
                name="password"
                fullWidth
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                required
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                variant="outlined"
                sx={{ marginTop: "2%" }}
                onChange={(e) => {
                  if (e.target.value === password) {
                    setPasswordMsg("MATCH");
                  } else {
                    setPasswordMsg("NOT_MATCH");
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passwordMsg === "MATCH" ? (
                <Typography color={"green"} variant={"h5"}>
                  Password matches
                </Typography>
              ) : (
                <Typography color={"red"} variant={"h5"}>
                  Password not match
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handlePasswordReset}
                disabled={loading || passwordMsg !== "MATCH"}
              >
                {loading ? "Updating" : "Update"}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}
