import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Spinner from "./Spinner";
import {
  useActivateFormMutation,
  useDeactivateFormMutation,
  useDeleteFormMutation,
  useFormQuery,
  useUpsertFormMutation,
} from "../graphql/generated/Form.generated";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormStatus } from "./FormList";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { daysOption, timeOption } from "./AddForm";
import ConfirmationDialog, { MessageType } from "./ConfirmationDialog";
import FormRecipients from "./FormRecipients";
import FormQuestions from "./FormQuestions";
import palette from "../theme/palette";

export interface FormValues {
  id: string;
  name: string;
  projectId: string;
  projectName: string;
  startDate?: string;
  endDate?: string;
  sendDays: string[];
  sendTime: string[];
  reminderSendDays: string[];
  reminderSendTime: string[];
  comment: string;
  enableQrScanPasscode: string;
  qrScanPasscode: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function FormDetail() {
  const { id } = useParams();
  const [deleteFormId, setDeleteFormId] = useState("");
  const [enablePasscode, setEnablePasscode] = useState("");

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [deactivateFormId, setDeactivateFormId] = useState("");

  const { data, loading, refetch } = useFormQuery({
    variables: {
      id: id!,
    },
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();
  const [activateForm] = useActivateFormMutation();
  const [deactivateForm] = useDeactivateFormMutation();
  const [mutateDeleteForm] = useDeleteFormMutation();
  const [mutateUpsertForm] = useUpsertFormMutation();

  const form = data?.form;
  let color = "green";

  if (form?.status === FormStatus.DELETED) color = "red";
  else if (form?.status === FormStatus.DRAFT) color = "orange";
  else if (form?.status === FormStatus.INACTIVE) color = "grey";

  let defaultValues = {
    id: "",
    name: "",
    projectId: "",
    projectName: "",
    startDate: "",
    endDate: "",
    sendDays: [],
    sendTime: [],
    reminderSendDays: [],
    reminderSendTime: [],
    enableQrScanPasscode: "",
    qrScanPasscode: "",
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (form) {
      reset({
        id: form.id,
        projectId: form.project.id,
        name: form.name,
        projectName: form.project.name,
        startDate: form.startDate || "",
        endDate: form.endDate || "",
        sendDays: form.sendDays ? form.sendDays.split("|") : [],
        sendTime: form.sendTime ? form.sendTime.split("|") : [],
        reminderSendDays: form.reminderSendDays
          ? form.reminderSendDays.split("|")
          : [],
        reminderSendTime: form.reminderSendTime
          ? form.reminderSendTime.split("|")
          : [],
        enableQrScanPasscode: form.enableQrScanPasscode ? "yes" : "no",
        qrScanPasscode: form.qrScanPasscode,
      });
      setEnablePasscode(form.enableQrScanPasscode ? "yes" : "no");
    }
  }, [form, reset, setEnablePasscode]);

  const [formId] = useState(
    "update-form" + Math.random().toString(36).slice(2),
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await mutateUpsertForm({
          variables: {
            input: {
              id: values.id,
              projectId: values.projectId,
              name: values.name,
              startDate: values.startDate
                ? dayjs(values.startDate).format("YYYY-MM-DD")
                : "",
              endDate: values.endDate
                ? dayjs(values.endDate).format("YYYY-MM-DD")
                : "",
              sendTime: values.sendTime.join("|"),
              sendDays: values.sendDays.join("|"),
              reminderSendTime: values.reminderSendTime.join("|"),
              reminderSendDays: values.reminderSendDays.join("|"),
              enableQrScanPasscode: values.enableQrScanPasscode === "yes",
              qrScanPasscode: values.qrScanPasscode,
            },
          },
        });
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
        await refetch();
      }
    },
    [mutateUpsertForm, refetch],
  );

  if (loading) return <Spinner />;

  return (
    <div style={{ paddingLeft: isDesktop ? "10%" : "0%" }}>
      <Box textAlign={"center"}>
        <Typography variant={"h3"}>Form Detail</Typography>
        <Box
          textAlign={"center"}
          sx={{
            backgroundColor: color,
            borderRadius: "10px",
            width: `${(form?.status?.length ?? 0) * 15}px`,
            margin: "0 auto",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "18px",
              fontWeight: 1000,
            }}
          >
            {form?.status}
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formId}
          noValidate
          style={{
            paddingTop: "2%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            maxWidth: "100%",
            marginBottom: "2%",
            paddingLeft: isDesktop ? "20%" : "5%",
            paddingRight: isDesktop ? "20%" : "5%",
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"name"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={"Name"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"projectName"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    disabled={true}
                    label={"Project"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"startDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      slotProps={{ textField: { fullWidth: true } }}
                      value={
                        field.value ? dayjs(field.value, "YYYY-MM-DD") : null
                      }
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="Start Date (optional)"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"endDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      slotProps={{ textField: { fullWidth: true } }}
                      value={
                        field.value ? dayjs(field.value, "YYYY-MM-DD") : null
                      }
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="End Date (optional)"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography>Enable QR code scan protection?</Typography>
              <Controller
                control={control}
                name={"enableQrScanPasscode"}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormControl
                        component="fieldset"
                        error={Boolean(fieldState.error)}
                        id={"passcode"}
                        required
                      >
                        <RadioGroup
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setEnablePasscode(e.target.value);
                          }}
                          row
                        >
                          <FormControlLabel
                            value={"yes"}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={"no"}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {fieldState.error?.message || " "}
                        </FormHelperText>
                      </FormControl>
                    </>
                  );
                }}
              />
            </Grid>

            {enablePasscode === "yes" && (
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name={"qrScanPasscode"}
                  rules={{
                    required:
                      "Passcode is required if QR scan protection is selected",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder={
                        "User must type right passcode to see the questions when scanning"
                      }
                      required={enablePasscode === "yes"}
                      label={"QR scan passcode (case sensitive)"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={12} sx={{ marginTop: "2%" }}>
              <Typography variant={"h5"}>
                Day and time of sending the form to verified recipients
                (optional)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"sendDays"}
                render={({ field: { value, onBlur, ...otherOptions } }) => {
                  return (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="send-day-label">Day</InputLabel>
                      <Select
                        multiple
                        required={false}
                        value={value}
                        labelId="send-day-label"
                        label="Day"
                        renderValue={(selected) => {
                          return selected
                            ? selected.map((t) => t.substring(0, 3)).join(", ")
                            : "";
                        }}
                        MenuProps={MenuProps}
                        {...otherOptions}
                      >
                        {daysOption.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              <Checkbox checked={value?.includes(option)} />
                              <ListItemText primary={option} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"sendTime"}
                render={({ field: { value, onBlur, ...otherOptions } }) => {
                  return (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="send-time-label">Time</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        required={false}
                        value={value}
                        labelId="send-time-label"
                        label="Time"
                        renderValue={(selected) => {
                          return selected?.join(", ");
                        }}
                        MenuProps={MenuProps}
                        {...otherOptions}
                      >
                        {timeOption.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              <Checkbox checked={value?.includes(option)} />
                              <ListItemText primary={option} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: "2%" }}>
              <Typography variant={"h5"}>
                Day and time of sending the form reminder to verified recipients
                (optional)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"reminderSendDays"}
                render={({ field: { value, onBlur, ...otherOptions } }) => {
                  return (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="reminder-day-label">Day</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        required={false}
                        value={value}
                        labelId="reminder-day-label"
                        label="Day"
                        renderValue={(selected) => {
                          return selected
                            ?.map((t) => t.substring(0, 3))
                            .join(", ");
                        }}
                        MenuProps={MenuProps}
                        {...otherOptions}
                      >
                        {daysOption.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              <Checkbox checked={value?.includes(option)} />
                              <ListItemText primary={option} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"reminderSendTime"}
                render={({ field: { value, onBlur, ...otherOptions } }) => {
                  return (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="reminder-time-label">Time</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        required={false}
                        value={value}
                        labelId="reminder-time-label"
                        label="Day"
                        renderValue={(selected) => {
                          return selected?.join(", ");
                        }}
                        MenuProps={MenuProps}
                        {...otherOptions}
                      >
                        {timeOption.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              <Checkbox checked={value?.includes(option)} />
                              <ListItemText primary={option} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1} // Adjust the value as needed for spacing
          p={2} // Optional: adds padding inside the Box
        >
          {(form?.status === FormStatus.ACTIVE ||
            form?.status === FormStatus.DRAFT) && (
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: palette.primary.main,
                  color: "white",
                },
              }}
              disabled={isSubmitting}
              type={"submit"}
              form={formId}
            >
              {isSubmitting ? "Saving" : "Update"}
            </Button>
          )}

          {form?.status !== FormStatus.DRAFT && (
            <Link to={`/submissions?formId=${form?.id}`}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: palette.primary.main,
                    color: "white",
                  },
                }}
              >
                Submissions
              </Button>
            </Link>
          )}

          {form?.status === FormStatus.DRAFT && (
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: palette.primary.main,
                  color: "white",
                },
              }}
              onClick={async () => {
                await activateForm({
                  variables: {
                    id: form?.id ?? "",
                  },
                });
                await refetch();
              }}
            >
              Activate
            </Button>
          )}
          {form?.status === FormStatus.ACTIVE && (
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: palette.primary.main,
                  color: "white",
                },
              }}
              onClick={() => setDeactivateFormId(id!)}
            >
              Deactivate
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: palette.primary.main,
                color: "white",
              },
            }}
            onClick={() => setDeleteFormId(id!)}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{ marginLeft: "10%", marginTop: "5%", marginRight: "10%" }}
      />
      <Grid sx={{ paddingTop: "5%" }}>
        <FormRecipients formId={id!} userPlan={form?.userPlan ?? ""} />
      </Grid>
      <Divider
        sx={{ marginLeft: "10%", marginTop: "5%", marginRight: "10%" }}
      />
      <Grid sx={{ paddingTop: "3%" }}>
        <FormQuestions formId={id!} />
      </Grid>

      {deleteFormId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deleteFormId}
          onClose={() => setDeleteFormId("")}
          onConfirm={async () => {
            await mutateDeleteForm({
              variables: {
                id: deleteFormId,
              },
            });
            setDeleteFormId("");
            navigate("/forms");
          }}
          text="Are you sure to delete this form?"
        />
      )}

      {deactivateFormId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deactivateFormId}
          onClose={() => setDeactivateFormId("")}
          onConfirm={async () => {
            await deactivateForm({
              variables: {
                id: id!,
              },
            });
            setDeactivateFormId("");
            await refetch();
          }}
          text="Are you sure to deactivate this form? Deactivated forms will be read only"
        />
      )}
    </div>
  );
}
