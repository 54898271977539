import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSupportCaseMutationVariables = Types.Exact<{
  subject: Types.Scalars['String']['input'];
  message: Types.Scalars['String']['input'];
}>;


export type CreateSupportCaseMutation = { __typename?: 'Mutation', createSupportCase: { __typename?: 'SupportCase', id: string } };


export const CreateSupportCaseDocument = gql`
    mutation CreateSupportCase($subject: String!, $message: String!) {
  createSupportCase(message: $message, subject: $subject) {
    id
  }
}
    `;
export type CreateSupportCaseMutationFn = Apollo.MutationFunction<CreateSupportCaseMutation, CreateSupportCaseMutationVariables>;

/**
 * __useCreateSupportCaseMutation__
 *
 * To run a mutation, you first call `useCreateSupportCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportCaseMutation, { data, loading, error }] = useCreateSupportCaseMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateSupportCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportCaseMutation, CreateSupportCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupportCaseMutation, CreateSupportCaseMutationVariables>(CreateSupportCaseDocument, options);
      }
export type CreateSupportCaseMutationHookResult = ReturnType<typeof useCreateSupportCaseMutation>;
export type CreateSupportCaseMutationResult = Apollo.MutationResult<CreateSupportCaseMutation>;
export type CreateSupportCaseMutationOptions = Apollo.BaseMutationOptions<CreateSupportCaseMutation, CreateSupportCaseMutationVariables>;