import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Spinner from "./Spinner";
import {
  useDeleteRecipientMutation,
  useRecipientsQuery,
} from "../graphql/generated/Recipient.generated";
import ContactVerify, { ContactType } from "./ContactVerify";
import AddRecipient from "./AddRecipient";
import VerifiedIcon from "@mui/icons-material/Verified";
import ConfirmationDialog, { MessageType } from "./ConfirmationDialog";
import palette from "../theme/palette";

export default function FormRecipients({
  formId,
  userPlan,
}: {
  formId: string;
  userPlan: string;
}) {
  const [addRecipient, setAddRecipient] = useState("");
  const [deleteRecipientId, setDeleteRecipientId] = useState("");
  const [deleteRecipient] = useDeleteRecipientMutation();

  const [recipientVerify, setRecipientVerify] = useState<{
    recipientId: string;
    type: ContactType;
    contact: string;
  } | null>(null);
  const { data, loading, refetch } = useRecipientsQuery({
    variables: {
      formId: formId,
    },
    fetchPolicy: "network-only",
  });

  const recipientRows = (data?.recipients || []).map((r) => {
    return {
      id: r.id,
      firstName: r.firstName,
      lastName: r.lastName,
      sendMethod: r.sendMethod,
      email: r.email,
      emailVerifyStatus: r.emailVerifyStatus,
      mobileNumberVerifyStatus: r.mobileNumberVerifyStatus,
      mobile: r.mobileNumber,
      status: r.status,
    };
  });

  if (loading) return <Spinner />;

  return (
    <div>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
        }}
      >
        <Typography variant={"h4"}>Recipients</Typography>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: palette.primary.main,
              color: "white",
            },
          }}
          disabled={userPlan.toUpperCase() === "FREE"}
          onClick={() => {
            setAddRecipient(formId);
          }}
        >
          Create
        </Button>
      </Stack>
      {userPlan.toUpperCase() === "FREE" && (
        <Box textAlign={"center"}>
          <Typography color={"grey"} style={{ fontSize: "12px" }}>
            You are on a free plan, please contact support to upgrade to paid
            plan to send forms to verified recipients.
          </Typography>
        </Box>
      )}
      <Grid
        container
        spacing={5}
        style={{
          paddingTop: "5%",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        {recipientRows.map((r) => {
          return (
            <Grid item xs={12} md={4} lg={4} key={r.id}>
              <Card
                sx={{
                  border: 1,
                  height: "100%",
                  borderColor: "rgb(212,213,213)",
                  // ":hover": {
                  //   borderColor: "#635DFF",
                  //   ":hover": {
                  //     boxShadow: 15,
                  //   },
                  // },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Box textAlign={"center"}>
                    <Typography variant={"h3"}>
                      {r.firstName} {r.lastName}{" "}
                    </Typography>
                  </Box>
                  <Typography>Send method: {r.sendMethod}</Typography>
                  {r.email && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography variant={"h5"}>{r.email}</Typography>
                      {r.emailVerifyStatus === "VERIFIED" ? (
                        <VerifiedIcon style={{ color: "green" }} />
                      ) : (
                        <Button
                          variant="outlined"
                          size={"small"}
                          sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: palette.primary.main,
                              color: "white",
                            },
                          }}
                          onClick={() =>
                            setRecipientVerify({
                              recipientId: r.id,
                              contact: r.email ?? "",
                              type: ContactType.EMAIL,
                            })
                          }
                        >
                          Verify
                        </Button>
                      )}
                    </Stack>
                  )}
                  {r.mobile && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography variant={"h5"}>{r.mobile}</Typography>
                      {r.mobileNumberVerifyStatus === "VERIFIED" ? (
                        <VerifiedIcon style={{ color: "green" }} />
                      ) : (
                        <Button
                          variant="outlined"
                          size={"small"}
                          sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: palette.primary.main,
                              color: "white",
                            },
                          }}
                          onClick={() =>
                            setRecipientVerify({
                              recipientId: r.id,
                              contact: r.mobile ?? "",
                              type: ContactType.MOBILE,
                            })
                          }
                        >
                          Verify
                        </Button>
                      )}
                    </Stack>
                  )}
                </CardContent>
                <CardActions
                  style={{
                    marginTop: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: palette.primary.main,
                        color: "white",
                      },
                    }}
                    onClick={() => setDeleteRecipientId(r.id)}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {addRecipient && (
        <div key={formId}>
          <AddRecipient
            formId={formId}
            refetch={refetch}
            open={!!addRecipient}
            onClose={() => {
              setAddRecipient("");
            }}
          />
        </div>
      )}
      {deleteRecipientId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deleteRecipientId}
          onClose={() => setDeleteRecipientId("")}
          onConfirm={async () => {
            await deleteRecipient({
              variables: {
                id: deleteRecipientId,
              },
            });
            setDeleteRecipientId("");
            await refetch();
          }}
          text="Are you sure to delete this recipient?"
        />
      )}
      {recipientVerify && (
        <ContactVerify
          open={!!recipientVerify.recipientId}
          onClose={async () => {
            setRecipientVerify(null);
            await refetch();
          }}
          recipientId={recipientVerify.recipientId}
          type={recipientVerify.type}
          contact={recipientVerify.contact}
        />
      )}
    </div>
  );
}
