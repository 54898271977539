const white = "#FFFFFF";

export const primary = {
  contrastText: white,
  main: "#635DFF",
  footer: "#ABB8C3",
};

const Palette = {
  primary,
  text: {
    primary: "rgb(0,0,0)",
    secondary: "rgb(0,0,0)",
    tertiary: "#c0dcd3",
    prompt: "red",
    link: "#1C77C3",
  },
};

export default Palette;
