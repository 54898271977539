import React, { useCallback, useEffect, useState } from "react";
import "./ProjectDetail.css";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useUpdateUserMutation,
  useUserQuery,
} from "../graphql/generated/User.generated";
import { Controller, useForm } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles";
import Spinner from "./Spinner";
import moment from "moment-timezone";
import { jwtDecode } from "jwt-decode";

export interface UserFormValues {
  id: string;
  email: string;
  emailVerified: boolean;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  mobileNumberVerified: boolean;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    paddingTop: "2%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "2%",
  },
}));

export default function UserProfile() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const token = localStorage.getItem("token") ?? "";
  const userExtRef = (jwtDecode(token) as any).userExtRef;
  const { data, loading, refetch } = useUserQuery({
    variables: {
      userExtRef,
    },
    fetchPolicy: "network-only",
  });

  const [updateUser] = useUpdateUserMutation();

  const classes = useStyles();

  let defaultValues = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: false,
    mobileNumber: "",
    mobileNumberVerified: false,
    subscriptionStartDate: "",
    subscriptionEndDate: "",
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<UserFormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (data?.user) {
      const u = data?.user;
      reset({
        id: u.id,
        firstName: u?.firstName ?? "",
        lastName: u?.lastName ?? "",
        email: u.email,
        emailVerified: u.emailVerified,
        mobileNumber: u?.mobileNumber ?? "",
        mobileNumberVerified: u?.mobileNumberVerified,
        subscriptionStartDate: u?.latestSubscription?.startDate
          ? moment(u?.latestSubscription?.startDate).format("YYYY-MM-DD")
          : "",
        subscriptionEndDate: u?.latestSubscription?.endDate
          ? moment(u?.latestSubscription?.endDate).format("YYYY-MM-DD")
          : "",
      });
    }
  }, [data?.user, reset]);

  const [formId] = useState(
    "user-profile-" + Math.random().toString(36).slice(2),
  );
  const onSubmit = useCallback(
    async (values: UserFormValues) => {
      try {
        await updateUser({
          variables: {
            input: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              mobileNumber: values.mobileNumber ?? "",
            },
          },
        });
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
        await refetch();
      }
    },
    [refetch, updateUser],
  );

  if (loading) return <Spinner />;

  return (
    <div>
      <Grid>
        <Box
          textAlign="center"
          sx={{
            paddingLeft: isDesktop ? "20%" : "5%",
            paddingRight: isDesktop ? "20%" : "5%",
          }}
        >
          <Typography variant={"h3"}>Profile</Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
            noValidate
            className={classes.formStyle}
          >
            <Grid container rowSpacing={2} columnSpacing={5}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"email"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      disabled
                      label={"Email"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"mobileNumber"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={"Mobile"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"firstName"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={"First Name"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"lastName"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={"Last Name"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {/*<Grid item xs={12} md={6}>*/}
              {/*  <Controller*/}
              {/*    control={control}*/}
              {/*    name={"subscriptionStartDate"}*/}
              {/*    render={({ field, fieldState }) => (*/}
              {/*      <TextField*/}
              {/*        {...field}*/}
              {/*        fullWidth*/}
              {/*        required*/}
              {/*        disabled*/}
              {/*        label={"Subscription Start"}*/}
              {/*        error={Boolean(fieldState.error)}*/}
              {/*        helperText={fieldState.error?.message}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} md={6}>*/}
              {/*  <Controller*/}
              {/*    control={control}*/}
              {/*    name={"subscriptionEndDate"}*/}
              {/*    render={({ field, fieldState }) => (*/}
              {/*      <TextField*/}
              {/*        {...field}*/}
              {/*        fullWidth*/}
              {/*        required*/}
              {/*        disabled*/}
              {/*        label={"Subscription End"}*/}
              {/*        error={Boolean(fieldState.error)}*/}
              {/*        helperText={fieldState.error?.message}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</Grid>*/}
            </Grid>
          </form>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type={"submit"}
            form={formId}
          >
            {isSubmitting ? "Saving" : "Update"}
          </Button>
        </Box>
      </Grid>
    </div>
  );
}
