import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosContext } from "../lib/axios";
import isEmail from "validator/lib/isEmail";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const isLengthValid = password.length >= 8;
  const hasNumber = /[0-9]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const isFormValid = () => {
    return (
      isEmail(email) &&
      isLengthValid &&
      hasNumber &&
      hasUpperCase &&
      hasLowerCase
    );
  };
  const handleLogin = async () => {
    if (isFormValid()) {
      setLoading(true);
      const res = await axiosContext().post("/user-login", {
        email,
        password,
      });

      if (res.data?.message !== "OK") {
        setMessage(res.data?.message);
      } else {
        localStorage.setItem("token", res.data.token);
        await delay(1000);
        navigate("/");
      }
      setLoading(false);
    } else {
      setMessage("Please fill in all the required fields");
    }
  };

  return (
    <>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton style={{ marginTop: "5%" }}>
            <img
              height={"90px"}
              src={
                "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
              }
              alt="Logo"
              className="logo"
            />
          </IconButton>
          <Typography variant="h3" sx={{ marginTop: "5%" }}>
            Welcome to{" "}
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
          <Box sx={{ marginTop: "10%" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              value={email}
              error={error}
              helperText={helperText}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
                if (e.target.value === "" || isEmail(e.target.value)) {
                  setError(false);
                  setHelperText("");
                } else {
                  setError(true);
                  setHelperText("Please enter a valid email address");
                }
              }}
            />

            <TextField
              required
              name="password"
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                "Minimum 8 characters, must include numbers (0-9), uppercase and lowercase letters"
              }
            />
            <Typography
              style={{ fontSize: "12px", marginTop: "10%", color: "grey" }}
            >
              By continuing, you agree to the InspectForm's
              <span>
                {" "}
                <a
                  href="https://inspectform.com/terms-of-use"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  Terms of Use
                </a>
              </span>{" "}
              and acknowledge you have read the InspectForm's
              <span>
                <a
                  href="https://inspectform.com/privacy-policy"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  Privacy Policy
                </a>
              </span>
            </Typography>

            <Button
              fullWidth
              variant="contained"
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? "Login..." : "Login"}
            </Button>
            {message && (
              <Typography variant={"h5"} color={"red"}>
                {message}
              </Typography>
            )}
            <Grid container sx={{ paddingTop: "2%" }}>
              <Grid
                item
                xs={6}
                md={6}
                justifyContent={"flex-start"}
                display={"flex"}
              >
                <Link to="/password-reset-request">Forgot password?</Link>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                justifyContent={"flex-end"}
                display={"flex"}
              >
                <Link to="/signup">Sign Up</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
