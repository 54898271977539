import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import dayjs from "dayjs";
import { useUpsertProjectMutation } from "../graphql/generated/Project.generated";
import TimezoneSelect, { type ITimezone } from "react-timezone-select";

export interface ProjectFormValues {
  name: string;
  startDate?: string;
  endDate?: string;
  address?: string;
  industry?: string;
  comment?: string;
}

interface AddProjectProps {
  refetch: () => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    marginBottom: "10%",
    marginTop: "2%",
  },
  divider: {
    color: palette.text.prompt,
  },
  timezoneSelect: {
    zIndex: 100,
  },
}));

export default function AddProject({
  open,
  onClose,
  refetch,
}: AddProjectProps) {
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const defaultValues = {
    name: "",
    startDate: "",
    endDate: "",
    address: "",
    industry: "",
    comment: "",
  };
  const [formId] = useState(
    "create-project" + Math.random().toString(36).slice(2),
  );

  const [mutateUpsertProject] = useUpsertProjectMutation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProjectFormValues>({
    defaultValues,
  });
  const classes = useStyles();

  const onSubmit = useCallback(
    async (values: ProjectFormValues) => {
      try {
        await mutateUpsertProject({
          variables: {
            name: values.name,
            timezone:
              typeof selectedTimezone === "string"
                ? selectedTimezone
                : (selectedTimezone as any)?.value,
            address: values.address,
            comment: values.comment,
            startDate: values.startDate
              ? dayjs(values.startDate).format("YYYY-MM-DD")
              : "",
            endDate: values.endDate
              ? dayjs(values.endDate).format("YYYY-MM-DD")
              : "",
          },
        });
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
        refetch();
        onClose();
      }
    },
    [mutateUpsertProject, onClose, refetch, selectedTimezone],
  );

  return (
    <Dialog open={open} scroll={"paper"}>
      <DialogContent dividers>
        <Typography
          sx={{
            typography: { xs: "h5r", sm: "h5r", md: "h5r" },
            paddingBottom: "2%",
          }}
        >
          All fields listed with * are required
        </Typography>

        <Typography>Project Timezone</Typography>
        <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
          displayValue={"UTC"}
          className={classes.timezoneSelect}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formId}
          noValidate
          className={classes.formStyle}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"name"}
                rules={{
                  required: "The name field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={"Name"}
                    error={Boolean(fieldState.error)}
                    // helperText={
                    //   fieldState.error?.message || (
                    //     <span style={{ color: "red" }}>
                    //       The name field can't be changed after creation"
                    //     </span>
                    //   )
                    // }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"address"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required={false}
                    label={"Address (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"startDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="Start Date (optional)"
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"endDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      slotProps={{ textField: { fullWidth: true } }}
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="End Date (optional)"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name={"comment"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={10}
                    fullWidth
                    required={false}
                    label={"Description (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type={"submit"}
          form={formId}
        >
          {isSubmitting ? "Saving" : "Create"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
