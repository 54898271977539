import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserQuery } from "../graphql/generated/User.generated";
import Spinner from "./Spinner";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import GridViewIcon from "@mui/icons-material/GridView";
import ListItemText from "@mui/material/ListItemText";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import MessageIcon from "@mui/icons-material/Message";
import { jwtDecode } from "jwt-decode";

export function userInitial(firstName: string, lastName: string) {
  return (
    (firstName ? firstName.substring(0, 1).toUpperCase() : "") +
    (lastName ? lastName.substring(0, 1).toUpperCase() : "")
  );
}

const iconSize = 20;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const activeColor = "#221af6";
const fontColor = "#635DFF";

export default function Topbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;
  const token = localStorage.getItem("token") ?? "";
  const userExtRef = (jwtDecode(token) as any).userExtRef;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };
  const { data, loading } = useUserQuery({
    variables: {
      userExtRef,
    },
    fetchPolicy: "network-only",
  });

  const handleLogout = () => {
    // Clear the token (assuming it's stored in localStorage)
    localStorage.removeItem("token");

    // Redirect to the login page
    navigate("/login");
  };

  if (loading) return <Spinner />;
  return (
    <Box sx={{ display: "flex", paddingBottom: "5%" }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: "white" }} component="nav">
        <Toolbar>
          {!isDesktop && (
            <IconButton
              edge="start"
              color="default"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <IconButton href={"/"} style={{ marginLeft: "3%" }}>
            <img
              height={"39px"}
              src={
                "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
              }
              alt="Logo"
              className="logo"
            />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, paddingLeft: "0.2%" }}
            style={{
              fontSize: "18px",
              fontWeight: 1000,
            }}
          >
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
          {isDesktop && (
            <Link to={`/profile`} style={{ marginRight: "1%" }}>
              <IconButton>
                <Avatar sx={{ bgcolor: "#B32D2A" }}>
                  {data?.user?.firstName || data?.user?.lastName
                    ? userInitial(
                        data?.user?.firstName ?? "",
                        data?.user?.lastName ?? "",
                      )
                    : userInitial(data?.user?.email ?? "", "")}
                </Avatar>
              </IconButton>
            </Link>
          )}
          {isDesktop && (
            <Button
              variant="contained"
              style={{ textTransform: "none", marginRight: "3%" }}
              onClick={handleLogout}
            >
              Log Out
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawerHeader
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Ensure space between elements
          }}
        >
          <Link to={`/profile`}>
            <IconButton onClick={toggleDrawer(false)}>
              <Avatar sx={{ bgcolor: "#B32D2A" }}>
                {data?.user?.firstName || data?.user?.lastName
                  ? userInitial(
                      data?.user?.firstName ?? "",
                      data?.user?.lastName ?? "",
                    )
                  : userInitial(data?.user?.email ?? "", "")}
              </Avatar>
            </IconButton>
          </Link>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            component={Link}
            to="/"
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon style={{ minWidth: "25%" }}>
              <GridViewIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{
                color: path === "/" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/projects"
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon style={{ minWidth: "25%" }}>
              <WorkIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/projects" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Projects"
              sx={{
                color: path === "/projects" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/forms"
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon style={{ minWidth: "25%" }}>
              <AssignmentIcon
                style={{
                  fontSize: iconSize,
                  color:
                    path === "/forms" || path.startsWith("/form")
                      ? activeColor
                      : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Forms"
              sx={{
                color:
                  path === "/forms" || path.startsWith("/form")
                    ? activeColor
                    : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/submissions"
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon style={{ minWidth: "25%" }}>
              <FactCheckIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/submissions" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Submissions"
              sx={{
                color: path === "/submissions" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            component={Link}
            to="/support"
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon style={{ minWidth: "25%" }}>
              <MessageIcon
                style={{
                  fontSize: iconSize,
                  color: path === "/support" ? activeColor : "grey",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Support"
              sx={{
                alignItems: "center",
                color: path === "/support" ? activeColor : fontColor,
              }}
            />
          </ListItem>
          <ListItem
            sx={{ flexDirection: "row" }}
            onClick={toggleDrawer(false)}
          ></ListItem>
          <Box textAlign={"center"}>
            <Button
              variant="contained"
              style={{ textTransform: "none" }}
              onClick={handleLogout}
            >
              Log Out
            </Button>
          </Box>
        </List>
      </Drawer>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}
