import {
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { axiosContext } from "../lib/axios";
import isEmail from "validator/lib/isEmail";

export default function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");
  const handlePasswordReset = async () => {
    if (isEmail(email)) {
      setLoading(true);
      await axiosContext().post("/password-reset-request", {
        email,
      });
      setRequestSent(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <CssBaseline />
        <IconButton style={{ marginTop: "1%" }}>
          <img
            height={"50px"}
            src={
              "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
            }
            alt="Logo"
            className="logo"
          />
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, paddingLeft: "2%" }}
            style={{
              fontSize: "18px",
              fontWeight: 1000,
            }}
          >
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {requestSent ? (
            <div>
              <Box textAlign={"left"}>
                <Typography sx={{ marginTop: "20%", marginBottom: "2%" }}>
                  Password reset instructions has been sent to {email}, please
                  check your email and follow the instructions to reset your
                  password.
                </Typography>
              </Box>
              <Box textAlign={"center"}>
                <Link
                  to={"https://app.inspectform.com"}
                  style={{ marginRight: "5%" }}
                >
                  <Button variant={"contained"} sx={{ textTransform: "none" }}>
                    Sign In
                  </Button>
                </Link>
              </Box>
            </div>
          ) : (
            <div>
              <Typography variant="h2" sx={{ marginTop: "30%" }}>
                Reset your password
              </Typography>
              <Box sx={{ marginTop: "10%" }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                  value={email}
                  error={error}
                  helperText={helperText}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value === "" || isEmail(e.target.value)) {
                      setError(false);
                      setHelperText("");
                    } else {
                      setError(true);
                      setHelperText("Please enter a valid email address");
                    }
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handlePasswordReset}
                  disabled={loading}
                  sx={{ marginTop: "2%", textTransform: "none" }}
                >
                  {loading ? "Sending" : "Send Email"}
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Container>
    </>
  );
}
