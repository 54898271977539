import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuestionsQueryVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
}>;


export type QuestionsQuery = { __typename?: 'Query', questions?: Array<{ __typename?: 'Question', id: string, description: string, displayOrder: number, answerType: string, answerChoices: Array<string> }> | null };

export type UpsertQuestionMutationVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
  input: Types.QuestionInput;
}>;


export type UpsertQuestionMutation = { __typename?: 'Mutation', upsertQuestion: { __typename?: 'Question', id: string } };

export type DeleteQuestionMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteQuestionMutation = { __typename?: 'Mutation', deleteQuestion: string };


export const QuestionsDocument = gql`
    query Questions($formId: String!) {
  questions(formId: $formId) {
    id
    description
    displayOrder
    answerType
    answerChoices
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables> & ({ variables: QuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export function useQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsSuspenseQueryHookResult = ReturnType<typeof useQuestionsSuspenseQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const UpsertQuestionDocument = gql`
    mutation UpsertQuestion($formId: String!, $input: QuestionInput!) {
  upsertQuestion(formId: $formId, input: $input) {
    id
  }
}
    `;
export type UpsertQuestionMutationFn = Apollo.MutationFunction<UpsertQuestionMutation, UpsertQuestionMutationVariables>;

/**
 * __useUpsertQuestionMutation__
 *
 * To run a mutation, you first call `useUpsertQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertQuestionMutation, { data, loading, error }] = useUpsertQuestionMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertQuestionMutation, UpsertQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertQuestionMutation, UpsertQuestionMutationVariables>(UpsertQuestionDocument, options);
      }
export type UpsertQuestionMutationHookResult = ReturnType<typeof useUpsertQuestionMutation>;
export type UpsertQuestionMutationResult = Apollo.MutationResult<UpsertQuestionMutation>;
export type UpsertQuestionMutationOptions = Apollo.BaseMutationOptions<UpsertQuestionMutation, UpsertQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation DeleteQuestion($id: String!) {
  deleteQuestion(id: $id)
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;