import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import dayjs from "dayjs";
import {
  useProjectQuery,
  useUpsertProjectMutation,
} from "../graphql/generated/Project.generated";
import Spinner from "./Spinner";

export interface ProjectFormValues {
  name: string;
  startDate?: string;
  endDate?: string;
  address?: string;
  industry?: string;
  comment?: string;
}

interface EditProjectProps {
  id: string;
  refetch: () => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  formStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "10%",
  },
  divider: {
    color: palette.text.prompt,
  },
}));

export default function EditProject({
  id,
  open,
  onClose,
  refetch,
}: EditProjectProps) {
  const { data: projectData, loading: projectLoading } = useProjectQuery({
    variables: {
      id: id ?? "",
    },
    fetchPolicy: "network-only",
  });

  let defaultValues = {
    name: "",
    startDate: "",
    endDate: "",
    address: "",
    comment: "",
  };
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<ProjectFormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (projectData?.project) {
      const proj = projectData.project;
      reset({
        name: proj.name,
        address: proj.address || "",
        startDate: proj.startDate || "",
        endDate: proj.endDate || "",
        comment: proj.comment || "",
      });
    }
  }, [projectData?.project, reset]);

  const [formId] = useState(
    "edit-project" + Math.random().toString(36).slice(2),
  );

  const [mutateUpsertProject] = useUpsertProjectMutation();

  const classes = useStyles();

  const onSubmit = useCallback(
    async (values: ProjectFormValues) => {
      try {
        await mutateUpsertProject({
          variables: {
            id: id,
            name: values.name,
            address: values.address,
            comment: values.comment,
            startDate: values.startDate
              ? dayjs(values.startDate).format("YYYY-MM-DD")
              : "",
            endDate: values.endDate
              ? dayjs(values.endDate).format("YYYY-MM-DD")
              : "",
          },
        });
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
        refetch();
        onClose();
      }
    },
    [id, mutateUpsertProject, onClose, refetch],
  );

  if (projectLoading) return <Spinner />;
  return (
    <Dialog open={open} scroll={"paper"}>
      <DialogContent dividers>
        <Typography
          sx={{
            typography: { xs: "h5r", sm: "h5r", md: "h5r" },
            paddingBottom: "2%",
          }}
        >
          All fields listed with * are required
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formId}
          noValidate
          className={classes.formStyle}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"name"}
                rules={{
                  required: "The name field is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    disabled={true}
                    label={"Name"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"address"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required={false}
                    label={"Address (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"startDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      slotProps={{ textField: { fullWidth: true } }}
                      value={
                        field.value ? dayjs(field.value, "YYYY-MM-DD") : null
                      }
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="Start Date (optional)"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"endDate"}
                render={({ field }) => {
                  return (
                    <DatePicker
                      slotProps={{ textField: { fullWidth: true } }}
                      value={
                        field.value ? dayjs(field.value, "YYYY-MM-DD") : null
                      }
                      onChange={(date) => field.onChange(date)}
                      format="YYYY-MM-DD"
                      label="End Date (optional)"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name={"comment"}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={10}
                    fullWidth
                    required={false}
                    label={"Description (optional)"}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type={"submit"}
          form={formId}
        >
          {isSubmitting ? "Saving" : "Update"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            refetch();
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
