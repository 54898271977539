import React, { PropsWithChildren } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as Provider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

export default function ApolloProvider({ children }: PropsWithChildren<{}>) {
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const accessToken = localStorage.getItem("token") ?? "";

    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions?.code === "UNAUTHENTICATED") {
          // Redirect to login page
          window.location.href = "/login";
        }
      }
    }

    if (
      networkError &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      // Redirect to login page
      window.location.href = "/";
    }
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return <Provider client={client}>{children}</Provider>;
}
