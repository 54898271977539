import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Spinner from "./Spinner";
import {
  useDeleteFormMutation,
  useFormsQuery,
} from "../graphql/generated/Form.generated";
import { Link, useSearchParams } from "react-router-dom";
import AddForm from "./AddForm";
import moment from "moment-timezone";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConfirmationDialog, { MessageType } from "./ConfirmationDialog";

const useStyles = makeStyles(() => ({
  formStyle: {
    paddingTop: "2%",
    paddingLeft: "20%",
    paddingRight: "20%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "2%",
  },
  divider: {
    color: palette.text.prompt,
  },
  media: {
    height: 200,
    width: "auto",
    // ":hover": {
    //   cursor: "",
    // },
  },
}));

export enum FormStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export default function FormList() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [addForm, setAddForm] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState("");
  const [mutateDeleteForm] = useDeleteFormMutation();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { data, loading, refetch } = useFormsQuery({
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "network-only",
  });

  const forms = data?.forms || [];

  if (loading) return <Spinner />;

  return (
    <div style={{ paddingLeft: isDesktop ? "10%" : "0%" }}>
      <Box textAlign={"center"}>
        <Button
          variant={"contained"}
          onClick={() => {
            setAddForm(true);
          }}
        >
          {" "}
          Create Form
        </Button>
        {forms.length === 0 && (
          <Typography sx={{ marginTop: "2%" }}>
            No forms available, please create your first form.
          </Typography>
        )}
      </Box>
      <Grid
        container
        columnSpacing={10}
        rowSpacing={10}
        style={{
          paddingTop: "5%",
          paddingLeft: isDesktop ? "10%" : "2%",
          paddingRight: isDesktop ? "10%" : "2%",
        }}
      >
        {forms.map((s) => {
          let color = "green";

          if (s.status === FormStatus.DELETED) color = "red";
          else if (s.status === FormStatus.DRAFT) color = "orange";
          else if (s.status === FormStatus.INACTIVE) color = "grey";

          return (
            <Grid item xs={12} md={6} lg={6} key={s.id}>
              <Card
                sx={{
                  border: 1,
                  borderColor: "rgb(212,213,213)",
                  // ":hover": {
                  //   borderColor: "#635DFF",
                  //   ":hover": {
                  //     boxShadow: 15,
                  //   },
                  // },
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Grid>
                    <Typography
                      variant={"h3"}
                      sx={{ paddingBottom: "1%" }}
                      style={{ wordWrap: "break-word" }}
                    >
                      {s.name}{" "}
                    </Typography>
                    <Box
                      textAlign={"center"}
                      sx={{
                        backgroundColor: color,
                        borderRadius: "10px",
                        width: `${s.status.length * 15}px`,
                        margin: "0 0", // Center the box horizontally
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: 1000,
                        }}
                      >
                        {s.status}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} md={6}>
                      <Typography variant={"h5"} sx={{ paddingTop: "5%" }}>
                        Project: {s.project.name}
                      </Typography>
                      <Typography sx={{ paddingTop: "5%" }} variant={"h5"}>
                        {s.numberOfSubmissions} submission(s)
                      </Typography>
                      <Typography variant={"h5"} sx={{ paddingTop: "3%" }}>
                        {s.numberOfQuestions} question(s)
                      </Typography>
                      <Typography variant={"h5"} sx={{ paddingTop: "3%" }}>
                        {s.numberOfRecipients} recipient(s)
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: isDesktop ? "10%" : "40%",
                          flexWrap: "wrap",
                        }}
                      >
                        <AccessTimeIcon />{" "}
                        <Typography fontSize={"12px"}>
                          Created {moment().diff(moment(s.createdAt), "d")}{" "}
                          day(s) ago
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={7} md={6}>
                      {s?.qrS3SignedUrl && (
                        <CardMedia
                          className={classes.media}
                          component="img"
                          alt="Uploaded Image"
                          image={s?.qrS3SignedUrl}
                          title={"Download QR code"}
                          onClick={() => window.open(s?.qrS3SignedUrl)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2} // Adjust the value as needed for spacing
                    style={{
                      marginLeft: isDesktop ? "5%" : "5%",
                      marginRight: isDesktop ? "5%" : "5%",
                    }}
                  >
                    {s.status !== FormStatus.DRAFT && (
                      <Link to={`/submissions?formId=${s?.id}`}>
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: palette.primary.main,
                              color: "white",
                            },
                          }}
                        >
                          Submissions
                        </Button>
                      </Link>
                    )}
                    <Link to={`/form/${s.id}`}>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: palette.primary.main,
                            color: "white",
                          },
                        }}
                      >
                        Detail
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: palette.primary.main,
                          color: "white",
                        },
                      }}
                      onClick={() => setDeleteFormId(s.id)}
                    >
                      Delete
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {addForm && (
        <AddForm
          refetch={refetch}
          open={addForm}
          onClose={() => {
            setAddForm(false);
          }}
        />
      )}

      {deleteFormId && (
        <ConfirmationDialog
          type={MessageType.WARNING}
          open={!!deleteFormId}
          onClose={() => setDeleteFormId("")}
          onConfirm={async () => {
            await mutateDeleteForm({
              variables: {
                id: deleteFormId,
              },
            });
            setDeleteFormId("");
            await refetch();
          }}
          text="Are you sure to delete this form?"
        />
      )}
    </div>
  );
}
