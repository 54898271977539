import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProjectDetail.css";

import {
  useProjectQuery,
  useUpsertProjectMutation,
} from "../graphql/generated/Project.generated";
import { Controller, useForm } from "react-hook-form";
import { ProjectFormValues } from "./AddProject";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import makeStyles from "@mui/styles/makeStyles";
import palette from "../theme/palette";

const useStyles = makeStyles(() => ({
  formStyle: {
    paddingTop: "2%",
    paddingLeft: "20%",
    paddingRight: "20%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "2%",
  },
  divider: {
    color: palette.text.prompt,
  },
  statusDraft: {
    color: "orange",
  },
  statusActive: {
    color: "green",
  },
  statusInactive: {
    color: "grey",
  },
}));

export enum FormStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export default function ProjectDetail() {
  const { id } = useParams();

  const classes = useStyles();

  const {
    data: projectData,
    loading: projectLoading,
    refetch,
  } = useProjectQuery({
    variables: {
      id: id ?? "",
    },
    fetchPolicy: "network-only",
  });

  let defaultValues = {
    name: "",
    startDate: "",
    endDate: "",
    address: "",
    comment: "",
  };

  const [formId] = useState(
    "create-project" + Math.random().toString(36).slice(2),
  );

  const [mutateUpsertProject] = useUpsertProjectMutation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<ProjectFormValues>({
    defaultValues,
  });
  useEffect(() => {
    if (projectData?.project) {
      const proj = projectData.project;
      reset({
        name: proj.name,
        address: proj.address || "",
        startDate: proj.startDate || "",
        endDate: proj.endDate || "",
        comment: proj.comment || "",
      });
    }
  }, [projectData?.project, reset]);

  const onSubmit = useCallback(
    async (values: ProjectFormValues) => {
      try {
        await mutateUpsertProject({
          variables: {
            id,
            name: values.name,
            address: values.address,
            comment: values.comment,
            startDate: values.startDate
              ? dayjs(values.startDate).format("YYYY-MM-DD")
              : "",
            endDate: values.endDate
              ? dayjs(values.endDate).format("YYYY-MM-DD")
              : "",
          },
        });
      } catch (e) {
        console.log("Error happens.");
        console.log(e);
      } finally {
        await refetch();
      }
    },
    [id, mutateUpsertProject, refetch],
  );

  if (!id || projectLoading) return <div>No id</div>;

  return (
    <div>
      <Grid>
        <Box textAlign="center" sx={{ paddingTop: "5%" }}>
          <Typography variant={"h4"}>Project Detail</Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
            noValidate
            className={classes.formStyle}
          >
            <Grid container rowSpacing={2} columnSpacing={5}>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name={"name"}
                  rules={{
                    required: "The name field is required",
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      disabled={true}
                      label={"Name"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name={"address"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required={false}
                      label={"Address (optional)"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name={"startDate"}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        value={dayjs(field.value, "YYYY-MM-DD")}
                        onChange={(date) => field.onChange(date)}
                        format="YYYY-MM-DD"
                        label="Start Date (optional)"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name={"endDate"}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        value={dayjs(field.value, "YYYY-MM-DD")}
                        onChange={(date) => field.onChange(date)}
                        format="YYYY-MM-DD"
                        label="End Date (optional)"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      rows={5}
                      required={false}
                      label={"Description (optional)"}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type={"submit"}
            form={formId}
          >
            {isSubmitting ? "Saving" : "Update Project"}
          </Button>
        </Box>
      </Grid>
    </div>
  );
}
