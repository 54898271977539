import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, name: string, address?: string | null, startDate?: string | null, endDate?: string | null, comment?: string | null, createdAt: any, deletedAt?: any | null }> };

export type ProjectCardsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectCardsQuery = { __typename?: 'Query', projectCards: Array<{ __typename?: 'ProjectCard', id: string, name: string, timezone: string, numberOfForms: number, numberOfActiveForms: number, numberOfInactiveForms: number, numberOfDraftForms: number, numberOfSubmissions: number, createdAt: any }> };

export type DashboardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', dashboard: { __typename?: 'Dashboard', numberOfProjects: number, numberOfForms: number, numberOfSubmissions: number, recentSubmissions: Array<{ __typename?: 'FormSubmission', id: string, submittedAt?: any | null, submittedBy?: string | null, form: { __typename?: 'Form', name: string, project: { __typename?: 'Project', name: string } } }> } };

export type ProjectQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, address?: string | null, startDate?: string | null, endDate?: string | null, comment?: string | null, createdAt: any } };

export type UpsertProjectMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  timezone?: Types.InputMaybe<Types.Scalars['String']['input']>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  address?: Types.InputMaybe<Types.Scalars['String']['input']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpsertProjectMutation = { __typename?: 'Mutation', upsertProject: { __typename?: 'Project', id: string } };

export type DeleteProjectMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: string };


export const ProjectsDocument = gql`
    query Projects {
  projects {
    id
    name
    address
    startDate
    endDate
    comment
    createdAt
    deletedAt
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export function useProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsSuspenseQueryHookResult = ReturnType<typeof useProjectsSuspenseQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectCardsDocument = gql`
    query ProjectCards {
  projectCards {
    id
    name
    timezone
    numberOfForms
    numberOfActiveForms
    numberOfInactiveForms
    numberOfDraftForms
    numberOfSubmissions
    createdAt
  }
}
    `;

/**
 * __useProjectCardsQuery__
 *
 * To run a query within a React component, call `useProjectCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectCardsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectCardsQuery, ProjectCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCardsQuery, ProjectCardsQueryVariables>(ProjectCardsDocument, options);
      }
export function useProjectCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCardsQuery, ProjectCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCardsQuery, ProjectCardsQueryVariables>(ProjectCardsDocument, options);
        }
export function useProjectCardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectCardsQuery, ProjectCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectCardsQuery, ProjectCardsQueryVariables>(ProjectCardsDocument, options);
        }
export type ProjectCardsQueryHookResult = ReturnType<typeof useProjectCardsQuery>;
export type ProjectCardsLazyQueryHookResult = ReturnType<typeof useProjectCardsLazyQuery>;
export type ProjectCardsSuspenseQueryHookResult = ReturnType<typeof useProjectCardsSuspenseQuery>;
export type ProjectCardsQueryResult = Apollo.QueryResult<ProjectCardsQuery, ProjectCardsQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  dashboard {
    numberOfProjects
    numberOfForms
    numberOfSubmissions
    recentSubmissions {
      id
      form {
        name
        project {
          name
        }
      }
      submittedAt
      submittedBy
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export function useDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardSuspenseQueryHookResult = ReturnType<typeof useDashboardSuspenseQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: String!) {
  project(id: $id) {
    id
    name
    address
    startDate
    endDate
    comment
    createdAt
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables> & ({ variables: ProjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export function useProjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectSuspenseQueryHookResult = ReturnType<typeof useProjectSuspenseQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const UpsertProjectDocument = gql`
    mutation UpsertProject($name: String!, $timezone: String, $id: String, $address: String, $startDate: String, $endDate: String, $comment: String) {
  upsertProject(
    name: $name
    timezone: $timezone
    id: $id
    address: $address
    startDate: $startDate
    endDate: $endDate
    comment: $comment
  ) {
    id
  }
}
    `;
export type UpsertProjectMutationFn = Apollo.MutationFunction<UpsertProjectMutation, UpsertProjectMutationVariables>;

/**
 * __useUpsertProjectMutation__
 *
 * To run a mutation, you first call `useUpsertProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectMutation, { data, loading, error }] = useUpsertProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      timezone: // value for 'timezone'
 *      id: // value for 'id'
 *      address: // value for 'address'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpsertProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectMutation, UpsertProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProjectMutation, UpsertProjectMutationVariables>(UpsertProjectDocument, options);
      }
export type UpsertProjectMutationHookResult = ReturnType<typeof useUpsertProjectMutation>;
export type UpsertProjectMutationResult = Apollo.MutationResult<UpsertProjectMutation>;
export type UpsertProjectMutationOptions = Apollo.BaseMutationOptions<UpsertProjectMutation, UpsertProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: String!) {
  deleteProject(id: $id)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;