import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecipientsQueryVariables = Types.Exact<{
  formId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type RecipientsQuery = { __typename?: 'Query', recipients?: Array<{ __typename?: 'Recipient', id: string, firstName: string, lastName: string, sendMethod?: string | null, email?: string | null, emailVerifyStatus?: string | null, mobileNumber?: string | null, mobileNumberVerifyStatus?: string | null, status?: string | null }> | null };

export type UpsertRecipientMutationVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
  input: Types.RecipientInput;
}>;


export type UpsertRecipientMutation = { __typename?: 'Mutation', upsertRecipient: { __typename?: 'Recipient', id: string, firstName: string, lastName: string, sendMethod?: string | null, email?: string | null, mobileNumber?: string | null, status?: string | null } };

export type RecipientAuthCodeMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  type: Types.Scalars['String']['input'];
  contact: Types.Scalars['String']['input'];
}>;


export type RecipientAuthCodeMutation = { __typename?: 'Mutation', recipientAuthCode: string };

export type RecipientAuthCodeVerifyMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  type: Types.Scalars['String']['input'];
  contact: Types.Scalars['String']['input'];
  authCode: Types.Scalars['String']['input'];
}>;


export type RecipientAuthCodeVerifyMutation = { __typename?: 'Mutation', recipientAuthCodeVerify: string };

export type DeleteRecipientMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteRecipientMutation = { __typename?: 'Mutation', deleteRecipient: string };


export const RecipientsDocument = gql`
    query Recipients($formId: String) {
  recipients(formId: $formId) {
    id
    firstName
    lastName
    sendMethod
    email
    emailVerifyStatus
    mobileNumber
    mobileNumberVerifyStatus
    status
  }
}
    `;

/**
 * __useRecipientsQuery__
 *
 * To run a query within a React component, call `useRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useRecipientsQuery(baseOptions?: Apollo.QueryHookOptions<RecipientsQuery, RecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipientsQuery, RecipientsQueryVariables>(RecipientsDocument, options);
      }
export function useRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipientsQuery, RecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipientsQuery, RecipientsQueryVariables>(RecipientsDocument, options);
        }
export function useRecipientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecipientsQuery, RecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipientsQuery, RecipientsQueryVariables>(RecipientsDocument, options);
        }
export type RecipientsQueryHookResult = ReturnType<typeof useRecipientsQuery>;
export type RecipientsLazyQueryHookResult = ReturnType<typeof useRecipientsLazyQuery>;
export type RecipientsSuspenseQueryHookResult = ReturnType<typeof useRecipientsSuspenseQuery>;
export type RecipientsQueryResult = Apollo.QueryResult<RecipientsQuery, RecipientsQueryVariables>;
export const UpsertRecipientDocument = gql`
    mutation UpsertRecipient($formId: String!, $input: RecipientInput!) {
  upsertRecipient(formId: $formId, input: $input) {
    id
    firstName
    lastName
    sendMethod
    email
    mobileNumber
    status
  }
}
    `;
export type UpsertRecipientMutationFn = Apollo.MutationFunction<UpsertRecipientMutation, UpsertRecipientMutationVariables>;

/**
 * __useUpsertRecipientMutation__
 *
 * To run a mutation, you first call `useUpsertRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRecipientMutation, { data, loading, error }] = useUpsertRecipientMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRecipientMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRecipientMutation, UpsertRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRecipientMutation, UpsertRecipientMutationVariables>(UpsertRecipientDocument, options);
      }
export type UpsertRecipientMutationHookResult = ReturnType<typeof useUpsertRecipientMutation>;
export type UpsertRecipientMutationResult = Apollo.MutationResult<UpsertRecipientMutation>;
export type UpsertRecipientMutationOptions = Apollo.BaseMutationOptions<UpsertRecipientMutation, UpsertRecipientMutationVariables>;
export const RecipientAuthCodeDocument = gql`
    mutation RecipientAuthCode($id: String!, $type: String!, $contact: String!) {
  recipientAuthCode(id: $id, type: $type, contact: $contact)
}
    `;
export type RecipientAuthCodeMutationFn = Apollo.MutationFunction<RecipientAuthCodeMutation, RecipientAuthCodeMutationVariables>;

/**
 * __useRecipientAuthCodeMutation__
 *
 * To run a mutation, you first call `useRecipientAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientAuthCodeMutation, { data, loading, error }] = useRecipientAuthCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useRecipientAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<RecipientAuthCodeMutation, RecipientAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientAuthCodeMutation, RecipientAuthCodeMutationVariables>(RecipientAuthCodeDocument, options);
      }
export type RecipientAuthCodeMutationHookResult = ReturnType<typeof useRecipientAuthCodeMutation>;
export type RecipientAuthCodeMutationResult = Apollo.MutationResult<RecipientAuthCodeMutation>;
export type RecipientAuthCodeMutationOptions = Apollo.BaseMutationOptions<RecipientAuthCodeMutation, RecipientAuthCodeMutationVariables>;
export const RecipientAuthCodeVerifyDocument = gql`
    mutation RecipientAuthCodeVerify($id: String!, $type: String!, $contact: String!, $authCode: String!) {
  recipientAuthCodeVerify(
    id: $id
    type: $type
    contact: $contact
    authCode: $authCode
  )
}
    `;
export type RecipientAuthCodeVerifyMutationFn = Apollo.MutationFunction<RecipientAuthCodeVerifyMutation, RecipientAuthCodeVerifyMutationVariables>;

/**
 * __useRecipientAuthCodeVerifyMutation__
 *
 * To run a mutation, you first call `useRecipientAuthCodeVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientAuthCodeVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientAuthCodeVerifyMutation, { data, loading, error }] = useRecipientAuthCodeVerifyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      contact: // value for 'contact'
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useRecipientAuthCodeVerifyMutation(baseOptions?: Apollo.MutationHookOptions<RecipientAuthCodeVerifyMutation, RecipientAuthCodeVerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientAuthCodeVerifyMutation, RecipientAuthCodeVerifyMutationVariables>(RecipientAuthCodeVerifyDocument, options);
      }
export type RecipientAuthCodeVerifyMutationHookResult = ReturnType<typeof useRecipientAuthCodeVerifyMutation>;
export type RecipientAuthCodeVerifyMutationResult = Apollo.MutationResult<RecipientAuthCodeVerifyMutation>;
export type RecipientAuthCodeVerifyMutationOptions = Apollo.BaseMutationOptions<RecipientAuthCodeVerifyMutation, RecipientAuthCodeVerifyMutationVariables>;
export const DeleteRecipientDocument = gql`
    mutation DeleteRecipient($id: String!) {
  deleteRecipient(id: $id)
}
    `;
export type DeleteRecipientMutationFn = Apollo.MutationFunction<DeleteRecipientMutation, DeleteRecipientMutationVariables>;

/**
 * __useDeleteRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipientMutation, { data, loading, error }] = useDeleteRecipientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecipientMutation, DeleteRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecipientMutation, DeleteRecipientMutationVariables>(DeleteRecipientDocument, options);
      }
export type DeleteRecipientMutationHookResult = ReturnType<typeof useDeleteRecipientMutation>;
export type DeleteRecipientMutationResult = Apollo.MutationResult<DeleteRecipientMutation>;
export type DeleteRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteRecipientMutation, DeleteRecipientMutationVariables>;