import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

export enum MessageType {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  WARNING = "WARNING",
}

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text: string;
  type: MessageType;
}

export default function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  text,
}: ConfirmationDialogProps) {
  const [submitting, setSubmitting] = useState(false);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box textAlign={"center"} sx={{ paddingTop: "5%" }}>
          <ErrorIcon
            style={{
              color: "red",
              width: "50px",
              height: "50px",
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          textAlign={"center"}
          sx={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", gap: "30px" }}
        sx={{ paddingBottom: "8%" }}
      >
        <Button
          sx={{
            borderRadius: "3",
            // opacity: "0.8",
            // color: "white",
            // "&:hover": {
            //   opacity: "1.0",
            // },
          }}
          variant={"contained"}
          onClick={() => {
            setSubmitting(true);
            onConfirm();
          }}
          disabled={submitting}
        >
          {submitting ? "Confirming" : "Confirm"}
        </Button>
        <Button onClick={onClose} variant={"contained"}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
