import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import isEmail from "validator/lib/isEmail";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosContext } from "../lib/axios";
import {
  Cancel,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { green, grey } from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sendingCode, setSendingCode] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");

  const [creatingUser, setCreatingUser] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [authCode, setAuthCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const isLengthValid = password.length >= 8;
  const hasNumber = /[0-9]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);

  const isFormValid = () => {
    return (
      isEmail(email) &&
      isLengthValid &&
      hasNumber &&
      hasUpperCase &&
      hasLowerCase
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value.toLowerCase());

    if (value === "" || isEmail(value)) {
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText("Please enter a valid email address");
    }
  };
  // const handleRegister = async () => {
  //   if (isFormValid()) {
  //     setLoading(true);
  //     const res = await axiosContext().post("/user-register", {
  //       email,
  //       password,
  //     });
  //     setLoading(false);
  //     if (res.data.toUpperCase() === "USER_EXISTS") {
  //       setMessage("Email is already registered, please login");
  //     } else {
  //       setMessage("SUCCESS");
  //     }
  //   } else {
  //     setMessage("Please fill in all the required fields");
  //   }
  // };
  return (
    <>
      <Container>
        <CssBaseline />
        <Box textAlign={"center"}>
          <IconButton style={{ marginTop: "5%" }}>
            <img
              height={"50px"}
              src={
                "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
              }
              alt="Logo"
              className="logo"
            />
            <Typography
              variant="h5"
              sx={{ flexGrow: 1, paddingLeft: "2%" }}
              style={{
                fontSize: "18px",
                fontWeight: 1000,
              }}
            >
              <span>
                <span style={{ color: "#B32D2A" }}>In</span>spect
                <span style={{ color: "#B32D2A" }}>Form</span>
              </span>
            </Typography>
          </IconButton>
          <Typography variant={"h3"} style={{ marginTop: "5%" }}>
            Sign up for InspectForm
          </Typography>

          <Grid item xs={12} md={12}>
            <TextField
              required
              id="email"
              label="Email Address"
              name="email"
              style={{ width: isDesktop ? "30%" : "90%", marginTop: "2%" }}
              disabled={openPassword || openVerify}
              value={email}
              error={error}
              helperText={helperText}
              onChange={handleChange}
            />
          </Grid>

          {!openVerify && !openPassword && (
            <Grid item xs={12} md={12}>
              <Button
                variant={"contained"}
                style={{ textTransform: "none", marginTop: "2%" }}
                disabled={sendingCode}
                onClick={async () => {
                  if (isEmail(email)) {
                    setSendingCode(true);
                    await axiosContext().post("/user-email-auth-code", {
                      email,
                    });
                    setSendingCode(false);
                    setOpenVerify(true);
                  }
                }}
              >
                {sendingCode ? "Sending" : "Verify Email Address"}
              </Button>
            </Grid>
          )}
        </Box>

        {openVerify && (
          <div>
            <Box textAlign={"center"}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  id="authcode"
                  label="Verification Code"
                  name="verificateCode"
                  style={{ width: isDesktop ? "30%" : "90%", marginTop: "2%" }}
                  error={error}
                  helperText={helperText}
                  onChange={(e) => setAuthCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  variant={"contained"}
                  style={{ textTransform: "none", marginTop: "1%" }}
                  disabled={verifyingCode}
                  onClick={async () => {
                    setVerifyingCode(true);
                    const res = await axiosContext().post("user-email-verify", {
                      email,
                      authCode,
                    });
                    setVerifyMessage(res.data.message);
                    if (res.data.message.toUpperCase() === "SUCCESS") {
                      setOpenVerify(false);
                      setOpenPassword(true);
                    }
                    setVerifyingCode(false);
                  }}
                >
                  {verifyingCode ? "Verifying" : "Verify"}
                </Button>
              </Grid>
            </Box>
            {verifyMessage.toUpperCase() === "FAILED" && (
              <Typography
                color={"red"}
                style={{
                  marginLeft: isDesktop ? "35%" : "5%",
                  fontSize: "13px",
                  width: isDesktop ? "30%" : "90%",
                  marginTop: "1%",
                }}
              >
                The verification code that you entered does not match. Check the
                code we sent through email and try again.
              </Typography>
            )}
            <Grid
              style={{ marginLeft: isDesktop ? "35%" : "5%", marginTop: "2%" }}
            >
              <Typography>Didn't get the code?</Typography>
              <List
                sx={{
                  listStyleType: "none",
                  pl: 1,
                  "& li::before": {
                    content: '"•"',
                    display: "inline-block",
                    width: "1em",
                    marginRight: "0.25em",
                    verticalAlign: "middle",
                  },
                }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    padding: 0,
                    margin: 0,
                    mb: 0.5, // Adjust as needed
                  }}
                >
                  <Box component="span">
                    Codes can take up to 5 minutes to arrive.
                  </Box>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    padding: 0,
                    margin: 0,
                    mb: 0.5, // Adjust as needed
                  }}
                >
                  <Box component="span">
                    Check your spam or other email folders
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </div>
        )}
        {openPassword && (
          <Box textAlign={"center"}>
            {" "}
            <Grid item xs={12} md={12}>
              <TextField
                required
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                style={{ width: isDesktop ? "30%" : "90%", marginTop: "2%" }}
                id="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText style={{ marginLeft: isDesktop ? "35%" : "5%" }}>
                Your password must meet the following criteria:
              </FormHelperText>
              <List style={{ marginLeft: isDesktop ? "35%" : "5%" }}>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                    {isLengthValid ? (
                      <CheckCircle
                        fontSize="small"
                        style={{ color: green[500] }}
                      />
                    ) : (
                      <Cancel fontSize="small" style={{ color: grey[500] }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="At least 8 characters"
                    primaryTypographyProps={{
                      color: isLengthValid ? green[500] : grey[500],
                      variant: "body2",
                    }}
                  />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                    {hasNumber ? (
                      <CheckCircle
                        fontSize="small"
                        style={{ color: green[500] }}
                      />
                    ) : (
                      <Cancel fontSize="small" style={{ color: grey[500] }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains numbers (0-9)"
                    primaryTypographyProps={{
                      color: hasNumber ? green[500] : grey[500],
                      variant: "body2",
                    }}
                  />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                    {hasUpperCase ? (
                      <CheckCircle
                        fontSize="small"
                        style={{ color: green[500] }}
                      />
                    ) : (
                      <Cancel fontSize="small" style={{ color: grey[500] }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains uppercase letters"
                    primaryTypographyProps={{
                      color: hasUpperCase ? green[500] : grey[500],
                      variant: "body2",
                    }}
                  />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                    {hasLowerCase ? (
                      <CheckCircle
                        fontSize="small"
                        style={{ color: green[500] }}
                      />
                    ) : (
                      <Cancel fontSize="small" style={{ color: grey[500] }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains lowercase letters"
                    primaryTypographyProps={{
                      color: hasLowerCase ? green[500] : grey[500],
                      variant: "body2",
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Button
              variant={"contained"}
              sx={{ marginTop: "2%" }}
              disabled={creatingUser}
              onClick={async () => {
                if (isFormValid()) {
                  setCreatingUser(true);
                  await axiosContext().post("/user-signup", {
                    email,
                    password,
                  });
                  setCreatingUser(false);
                  navigate("/user-reg-thank");
                }
              }}
            >
              {creatingUser ? "Creating" : "Create"}
            </Button>
            {verifyMessage.toUpperCase() === "SUCCESS" && (
              <Typography
                color={"green"}
                style={{ fontSize: "12px", marginTop: "2%" }}
              >
                Email verified, please set your password
              </Typography>
            )}
          </Box>
        )}
      </Container>
    </>
  );
}
