import React from "react";
import { Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import ProjectList from "./components/ProjectList";
import ProjectDetail from "./components/ProjectDetail";
import theme from "./theme";
import FormSubmissions from "./components/FormSubmissions";
import { NotFoundPage } from "./components/NotFoundPage";
import UserProfile from "./components/UserProfile";
import FormList from "./components/FormList";
import Dashboard from "./components/Dashboard";
import FormDetail from "./components/FormDetail";
import "./App.css";
import SupportCase from "./components/SupportCase";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import PasswordResetRequest from "./components/PasswordResetRequest";
import PasswordResetSubmit from "./components/PasswordResetSubmit";
import SignUp from "./components/SignUp";

export default function App() {
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/password-reset-request"
              element={<PasswordResetRequest />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/password-reset/:token"
              element={<PasswordResetSubmit />}
            />
            {/* Private route using PrivateRoute component */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <ProjectList />
                </PrivateRoute>
              }
            />
            <Route
              path="/forms"
              element={
                <PrivateRoute>
                  <FormList />
                </PrivateRoute>
              }
            />
            <Route
              path="/submissions"
              element={
                <PrivateRoute>
                  <FormSubmissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/form/:id"
              element={
                <PrivateRoute>
                  <FormDetail />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/projects/:id"
              element={
                <PrivateRoute>
                  <ProjectDetail />
                </PrivateRoute>
              }
            />

            <Route
              path="/support"
              element={
                <PrivateRoute>
                  <SupportCase />
                </PrivateRoute>
              }
            />

            <Route
              path="*"
              element={
                <PrivateRoute>
                  <NotFoundPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
