import React from "react";
import { Dialog, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface Props {
  imageUrl: string;
  open: boolean;
  onClose: () => void;
}

export default function ImageFullDisplay({ imageUrl, open, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        direction="row"
        sx={{
          display: "overlay",
        }}
      >
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CancelIcon sx={{ color: "grey" }} />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={() => {
            window.open(imageUrl);
          }}
        >
          <FileDownloadIcon sx={{ color: "grey" }} />
        </IconButton>
      </Stack>
      <img src={imageUrl} alt={"Full Display"} />
    </Dialog>
  );
}
