import { Navigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import React from "react";
import Topbar from "./Topbar";

export default function PrivateRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const isAuthenticated = !!localStorage.getItem("token");
  return isAuthenticated ? (
    <>
      {" "}
      <Topbar />
      <Sidebar />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
}
